"use client";

import { useContext, useEffect, useState } from "react";
import Button from "@/components/atoms/Button";
import { usePlatformAndOS } from "@/helpers/usePlatformAndOS";
import { useDeviceInfo } from "@/helpers/userDeviceType";
import { getGAPayload } from "@/helpers/getGAPayload";
import { IModalActions, ModalTypes } from "@/types/modal";
import { ModalContext } from "@/context/ModalContext";
import { useQueryParams } from "@/helpers/useQueryParams";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";
import { StoreContextValue } from "@/context/StoreSession";

interface CommonButtonProps {
  href?: string;
  CTATarget?: string;
  GAData?: Record<string, any>;
  children: React.ReactNode;
  size?: string;
  bg?: string;
  color?: string;
  icon?: string;
  hoverIcon?: string;
  className?: any;
  type?: 'submit' | 'button' | 'link'
  disabled?: boolean;
  scroll?: boolean;
  imageLoadingStrategy?: "lazy" | "eager";
  pageType?: string;
  modalData?:{},
  modalType?:ModalTypes,
  programTitle?:string
  isEventOnly?: boolean;
  closeModal?: boolean;
  GADataNew?:Record<string, any>;
  onClickStorePayload ? : Record<string, any>;
}

function handleStateUpdate(
  payload: Partial<StoreContextValue["state"]>, 
  updateStateSession: (state: Partial<StoreContextValue["state"]>) => void
) {
  if (payload && Object.keys(payload).length > 0) {
    const updatedState: Partial<StoreContextValue["state"]> = {
      pageType: payload.pageType || "NA",
      formSectionName: payload.formSectionName || "NA",
      sectionTitle: payload.sectionTitle || "NA",
      leadStartingCta: payload.leadStartingCta || "NA",
      parentSection: payload.parentSection || "NA",
    };

    updateStateSession(updatedState);
  }
}

const CommonButton: React.FC<CommonButtonProps> = ({
  href = "",
  CTATarget,
  GAData,
  children,
  size = "",
  bg = "",
  color = "",
  icon = "",
  hoverIcon = "",
  className = "",
  type = "",
  disabled = false,
  scroll = false,
  imageLoadingStrategy = "lazy",
  pageType = "",
  isEventOnly = false,
  modalData,
  modalType,
  programTitle,
  closeModal = false,
  GADataNew,
  onClickStorePayload = {}
}) => {
  const queryParams = useQueryParams();
  const { platform = "", version = "" } = usePlatformAndOS()
  const deviceType = useDeviceInfo()
  const [pageTitle, setPageTitle] = useState<string>("")
  const [pageReferrer, setPageReferrer] = useState<string>("")
  const { modalDispatch } = useContext(ModalContext)
 

  useEffect(() => {
    setPageReferrer(document.referrer)
    setPageTitle(document.title)
    const handleTitleChange = () => {
      setPageTitle(document.title)
    }
    document.addEventListener("titlechange", handleTitleChange)
    return () => {
      document.removeEventListener("titlechange", handleTitleChange)
    }
  }, [])

  const handleGaClick = () => {
    // sending only event data in datalayer
      if (
        (window as any).dataLayer && 
        ((GADataNew && Object.keys(GADataNew).length > 0 && GADataNew.event && GADataNew.event !== "") || 
        (Object.keys(GAData).length > 0 && GAData.event && GAData.event !== ""))
      ) {
        if(onClickStorePayload && Object.keys(onClickStorePayload).length > 0 ){
          handleStateUpdate(onClickStorePayload, updateStateSession);
        }
        
        if(isEventOnly){
          
          const { isPageType = false, page_url=false, ...restGADataNew } =  GADataNew || {};
          const dataLayerData = {
            ...restGADataNew,
            ...(isPageType && { page_type: getGlobalStateValue('pageType') }),
            ...(page_url && { page_url: window.location.href }),
          };
          
          (window as any).dataLayer.push(dataLayerData);

          return
        }else{
          const { isPageType = false, page_url=false, ...restGADataNew } =  GADataNew || {};
          const dataLayerData = {
            ...restGADataNew,
            ...(isPageType && { page_type: getGlobalStateValue('pageType') }),
            ...(page_url && { page_url: window.location.href }),
          };
          
          (window as any).dataLayer.push(dataLayerData);
          
          (window as any).dataLayer.push(GAData);
          return
        
        }
      }

    // sending other parameters in datalayer
    let pageUrl = href
    if (!pageUrl.startsWith("http://") && !pageUrl.startsWith("https://")) {
      if (pageType === "origin") {
        const origin = window.location.origin
        pageUrl = `${origin}/${pageUrl}`
      } else {
        const fullUrl = window.location.href
        pageUrl = `${fullUrl}`
      }
    }
    const otherInfo = {
      platform,
      deviceType,
      version,
      pageTitle,
      pageReferrer,
      pageUrl,
    };
    
    if (Object.keys(GAData).length > 0 && GAData.event && GAData.event !== "") {
      const payload = getGAPayload(GAData, queryParams, otherInfo)
      if (typeof window !== "undefined" && (window as any).dataLayer) {
        const existingEventIndex = (window as any).dataLayer.findIndex(
          (e: any) => e && typeof e.event !== 'undefined' && e.event === payload.event
        );
        if (existingEventIndex === -1) {
          (window as any).dataLayer.push(payload);
        }
      }
    }
  }

  function curry(...funcArgs: any[]) {
    return () => {
      if (funcArgs.length > 0) {
        funcArgs.forEach((func) => func())
      }
    }
  }

  function getButtonProps({
    href,
    CTATarget,
    GAData,
    GADataNew,
    type,
    closeModal,
    modalData,
    programTitle,
    onClickStorePayload
  }) {
    let buttonProps: any = {}
    let modalAction = () => {}
    const applyGaEvent = (GADataNew && Object.keys(GADataNew).length > 0 && GADataNew.event && GADataNew.event !== "") || (GAData && Object.keys(GAData).length > 0 && GAData.event !== "");
    if (href && type !== "submit") {
      buttonProps = {
        ...buttonProps,
        href,
        scroll,
        target: `${CTATarget || "self"}`,
      }
    }
    if (type === "button" && !closeModal) {
      modalAction = () =>
        modalDispatch({
          type: IModalActions.OpenModal,
          data: {
            modalType,
            modalData: { ...modalData, url: href, programTitle },
          },
        })
    }
    if (closeModal) {
      modalAction = () =>
        modalDispatch({
          type: IModalActions.CloseModal,
        })
    }
    
    if (applyGaEvent) {
      buttonProps.onClick = curry(modalAction, modalAction, handleGaClick);
    }else{
      buttonProps.onClick = curry(modalAction, modalAction);
    }
    return buttonProps
  }

  const buttonProps = getButtonProps({
    href,
    CTATarget,
    GAData,
    GADataNew,
    type,
    closeModal,
    modalData,
    programTitle,
    onClickStorePayload
  })

  return (
    <>
      <Button
        buttonProps={buttonProps}
        size={size}
        bg={bg}
        color={color}
        icon={icon}
        hoverIcon={hoverIcon}
        className={className}
        type={type}
        disabled={disabled}
        imageLoadingStrategy={imageLoadingStrategy}
      >
        {children}
      </Button>
    </>
  )
}
export default CommonButton;
