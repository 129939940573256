import { Field } from "react-final-form";
import s from "./OTPInput.module.scss";
import { ChangeEvent, useEffect, useId, useRef, useState } from "react";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";

function OTPInputView({ value, onChange, className, message, loading, isDarkForm=false }) {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const id = useId();
  
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (message && !loading) {
      const el = ref.current;
      if (el) {
        const inputs = el.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].value = "";
        }
      }
    }
  }, [message, loading]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = e.target.value.replace(/\D/g, ""); //only allow numeric digit
    const newOtp = [...otp];
    newOtp[index] = newValue;
    setOtp(newOtp);
    onChange(newOtp.join(""));
    if (newValue && index < 6 - 1) {
      inputRefs.current[index + 1]?.focus();
    }
    if (index === 5 && newValue) {
      pushToDataLayer({
        'event':'otp_verification_card_fill',
        'field_name':'OTP',
        'section_name': getGlobalStateValue("parentSection") || "NA",
        'page_type': getGlobalStateValue('pageType')  || "Home Page",
        'page_url' : window?.location?.href || "NA"
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (!newOtp[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      newOtp[index] = "";
      setOtp(newOtp);
      onChange(newOtp.join(""));
    }
  };

  return (
    <div className={`${s.root} ${className}`} ref={ref}>
      {otp.map((value, index) => (
        <input
          key={index}
          type="text"
          value={value}
          id={`otp_${id}_${index}`}
          maxLength={1}
          className={`${s.input6} ${isDarkForm ? s.inputDark : ""}`}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          disabled={loading}
          autoComplete="off"
          pattern="[0-9]*" 
          inputMode="numeric"
          ref={(el) => {
            inputRefs.current[index] = el;
          }}
        />
      ))}
    </div>
  );
}

export default function OTPInput(props) {
  return (
    <Field
      name={props.name}
      render={({ input: { value, onChange } }) => (
        <OTPInputView
          value={value}
          onChange={onChange}
          className={props.className}
          message={props.message}
          loading={props.loading}
          isDarkForm={props.isDarkForm}
        />
      )}
    />
  );
}