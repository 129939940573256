const getProgramId = ({
  programId,
  associatedPrograms,
}: {
  programId: string;
  associatedPrograms: Array<ProgramDetails>;
}) => {
  if (!associatedPrograms.length) {
    return programId;
  } else {
    const firstObject = associatedPrograms[0];
    const programId = firstObject.attributes.BasicDetails.PROGRAM_ID;
    return programId;
  }
};

const getSpecializationValue = ({ associatedPrograms }) => {
  if (!associatedPrograms.length) {
    return "";
  } else {
    const firstObject = associatedPrograms[0];

    const specializationText = firstObject.attributes.BasicDetails.SpecializationText;
    return specializationText;
  }
};
const disabledButtonPaths = [
  "/lp-nw",
  "/lp-nw/mca",
  "/lp-nw/bca",
  "/lp-nw/mba",
  "/lp-nw/bba"
]

export { getProgramId, getSpecializationValue, disabledButtonPaths };
