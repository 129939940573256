import { useState } from "react";
import s from "./TextInput.module.scss";
export default function TextInput(props) {
  return (
    <input
      {...props}
      className={`${s.root} ${props.className}`}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
    />
  );
}

export function PasswordInput(props) {
  const [shown, show] = useState(false);
  return (
    <>
      <TextInput {...props} type={shown ? "text" : "password"} />
      <svg
        onClick={(e) => {
          show(!shown);
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className="cursor-pointer"
      >
        <g opacity="0.4">
          <path
            d="M17.8807 19.2968C16.1794 20.3755 14.1618 21.0002 11.9983 21.0002C6.60617 21.0002 2.1202 17.1204 1.17969 12.0002C1.61637 9.62283 2.81737 7.5129 4.51852 5.93457L1.39171 2.80777L2.80593 1.39355L22.6049 21.1925L21.1907 22.6068L17.8807 19.2968ZM5.93365 7.3497C4.60478 8.56015 3.63585 10.1672 3.22083 12.0002C4.13827 16.0521 7.76265 19.0002 11.9983 19.0002C13.5977 19.0002 15.11 18.5798 16.4223 17.8384L14.394 15.8101C13.7003 16.2472 12.8788 16.5002 11.9983 16.5002C9.51303 16.5002 7.49831 14.4854 7.49831 12.0002C7.49831 11.1196 7.75122 10.2981 8.18836 9.60442L5.93365 7.3497ZM12.9119 14.328L9.67051 11.0866C9.55935 11.3696 9.49831 11.6777 9.49831 12.0002C9.49831 13.3809 10.6176 14.5002 11.9983 14.5002C12.3207 14.5002 12.6289 14.4391 12.9119 14.328ZM20.8048 16.5925L19.374 15.1617C20.0299 14.2268 20.5134 13.1586 20.7757 12.0002C19.8583 7.94818 16.2339 5.00016 11.9983 5.00016C11.1524 5.00016 10.3309 5.11773 9.55054 5.33818L7.97251 3.76015C9.21932 3.26959 10.5773 3.00016 11.9983 3.00016C17.3904 3.00016 21.8764 6.87993 22.8169 12.0002C22.5047 13.6998 21.8018 15.2628 20.8048 16.5925ZM11.7209 7.50857C11.8126 7.503 11.9051 7.50016 11.9983 7.50016C14.4835 7.50016 16.4983 9.51488 16.4983 12.0002C16.4983 12.0933 16.4954 12.1858 16.4899 12.2775L11.7209 7.50857Z"
            fill="black"
          />
        </g>
      </svg>
    </>
  );
}

export function SelectInput({ input, meta, ...props }) {
  return (
    <>
      <select
        {...input}
        className={`${s.root} ${props.className}`}
        name={input.name}
        value={input.value}
       
      >
        {props?.options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && (
        <span className={s.error}>{meta.error}</span>
      )}
    </>
  );
}
