"use client";

import { getGlobalStateValue } from "@/context/utils";
import { pushToDataLayer } from "@/helpers/gtag";
import styles from "./HeroCarousel.module.scss";

const ClientWrapper = (props) => {
  const onClickHandler = () => {
    pushToDataLayer({
      event: "banner_course_interaction",
      cta_text: props.Program,
      section_name: "Hero Banner",
      header_title: props.Titles?.replace(/<\/?[^>]+(>|$)/g, "") || "NA",
      page_type: getGlobalStateValue("pageType"),
    });
  };

  return (
    <div className="banner-course-item" key={props.href}>
      <a onClick={onClickHandler} href={props.href}>
        <span className={styles.program}>{props.Program}</span>
        <p>{props.ProgramName}</p>
      </a>
    </div>
  );
};

export default ClientWrapper;
