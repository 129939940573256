import Link from "next/link";
import styles from "./BlogCard.module.scss";
import cn from "clsx";
import { pushToDataLayer } from "@/helpers/gtag";
import dayjs from "dayjs";
import { getGlobalStateValue } from "@/context/utils";
import ShowImageOnViewPort from "@/helpers/ShowImageOnViewPort";
import {imgPathReplace} from '@/helpers/imgPathReplace';

export default function BlogCard({
  Heading,
  url_key,
  BannerImage,
  updatedAt,
  enabled: _enabled,
  ReadTimeText,
  PrimaryCategory,
  isBlogList = false,
  title
}: any) {
  const img1 = BannerImage?.data?.attributes; 
  const provider = ReadTimeText?.text;

  const GAEventsHandler = (category) => {
    pushToDataLayer({
      'event':'card_interaction',
      'cta_text': Heading,
      'section_name': category || "NA",
      'page_type': getGlobalStateValue("pageType")
    })
  }

  return (
    <Link
      onClick={()=>GAEventsHandler(PrimaryCategory?.split("_")?.join(" "))}
      href={url_key ? `/${url_key}` :  "#"}
      scroll={Boolean(url_key)}
      target="_self"
      className={`block border-[0.0625rem] border-blu rounded-2xl lg:rounded-[0.833rem] ${styles.root}`}
      prefetch={false}
    >
      <div
        className={cn("flex flex-col justify-end", styles.content, {
          [styles.gradient]: isBlogList,
        })}
      >
        <ShowImageOnViewPort
        src={imgPathReplace(img1?.url)}
        alt={img1?.alternativeText || "Amity Online"}
        className="object-cover lg:rounded-[0.833rem]" 
        width="20.25rem"
        height="18.125rem"
        title="Amity Online"
        />

        {PrimaryCategory && isBlogList && (
          <p className={cn(styles.strip)}>
            {PrimaryCategory?.split("_")?.join(" ")}
          </p>
        )}
      </div>
      <div className={`font-raleway ${styles.bottom}`}>
        <div className={`font-bold flex items-center ${styles.heading}`}>
          {dayjs(updatedAt).format("MMM DD, YYYY")} . 
          <img src='https://amityonline.com/_s/time_e8c8f780bf.webp' alt='clock icon' className={` mx-1 ${styles.clockIcon}`} loading="lazy" />
          {provider}
        </div>
        <div className={`font-semibold line-clamp-2  ${styles.text}`}>{Heading}</div>
        <div className={`${styles.arrow} ${styles.blogArrow}`}><img src={imgPathReplace('https://amityonline.com/_s/iconmonstr_arrow_right_circle_thin_f882a47d7c.webp')} loading="lazy"  alt='news stories' width="2.625rem" height="2.625rem" title="News Stories" /></div>
      </div>
    </Link>
  );
}
