"use client";

import s from "./LoginModal.module.scss";

import Button from "@/components/atoms/Button";
import FormGroup from "@/components/atoms/Form/FormGroup";
import TextInput, { PasswordInput } from "@/components/atoms/Form/TextInput";
import { useState } from "react";
import { Field, Form } from "react-final-form";

const LoginWithEmail = ({setTab}) => {
  const [message, setMessage] = useState(null);
  const [isLoading, loading] = useState(false);

  const handleSubmit = (e) => {
    loading(true);
    fetch("/api/login", {
      method: "post",
      body: JSON.stringify(e),
    })
      .then((d) => d.json())
      .then((d) => {
        if (d.redirectUrl) {
          window.location.href = d.redirectUrl;
        } else {
          setMessage(d.message);
        }
      })
      .catch((e) => {})
      .finally(() => {
        loading(false);
      });
  };

  const validate = (values) => {
    const emailRegex = /^[a-zA-Z0-9+_.-]+@(?:[a-zA-Z0-9.-]+\.)+([a-zA-Z]{2,})$/;
    const errors: any = {};
    if (!emailRegex.test(values.email)) {
      errors.email = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };
  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {(props) => (
        <form
          className={`${s.content} py-4 flex flex-col`}
          onSubmit={props.handleSubmit}
        >
          <div
            className={`text-blu font-baskervville font-normal capitalize mb-3 lg:mb-4 ${s.header}`}
          >
            Student Login
          </div>
          <div
            className={`text-sec font-railway font-medium mb-7 lg:mb-9 ${s.text}`}
          >
            Enter your details here to login to your account
          </div>
          <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-x-4 mb-9">
            <Button
              bg="s"
              type="button"
              buttonProps={{
                onClick: ()=>setTab("withEmail"),
              }}
            >
              Login with email
            </Button>
            <Button
              bg="o"
              type="button"
              buttonProps={{
                onClick: ()=>setTab("withOTP"),
              }}
            >
              Login with otp
            </Button>
          </div>
          {message ? <div className="text-red-800">{message}</div> : null}
          <Field name="email">
            {({ input, meta }) => {
              return (
                <FormGroup
                  label="Email Id/ Form Id"
                  required
                  className="font-railway"
                >
                  <TextInput
                    {...input}
                    name="email"
                    placeholder="Enter Email Id"
                  />
                </FormGroup>
              );
            }}
          </Field>
          <Field name="password">
            {({ input, meta }) => {
              return (
                <FormGroup
                  label="Password"
                  required
                  className="font-railway"
                  helperContent={
                    <button className="cursor-pointer" onClick={()=>setTab("forgetPass")} >
                      Forgot Password?
                    </button>
                  }
                >
                  <PasswordInput {...input} placeholder="Enter Password " />
                </FormGroup>
              );
            }}
          </Field>
          <div className="mb-4"></div>
          <Button
            className="mt-auto"
            type="submit"
            disabled={props.hasValidationErrors || isLoading}
          >
            SUBMIT
          </Button>
          {/* </div> */}
        </form>
      )}
    </Form>
  );
};

export default LoginWithEmail;
