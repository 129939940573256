export const trackingDetails = () => {
  // const params = new URLSearchParams(window.location.search);
  try {
    var params = JSON.parse(sessionStorage.getItem("utm_params")) || {};
  } catch (e) {
    params = {};
  }

  // Extract gclid from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const gclid = urlParams.get('gclid') || ""; // Get gclid from URL

  return {
    pageUrl:
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname,
    pageName: window.document.title,
    Custom21: gclid, // Use the extracted gclid
    ...params,
  };
};
