import React from "react";

export default function Camera() {
  return (
    <svg width={20} height={12} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_941_578)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00195312 9.88347V2.2798C0.00195312 1.64531 0.308429 1.16312 0.713916 0.760118C1.06666 0.409641 1.65155 0.163265 2.34531 0.163265H11.172C11.897 0.163265 12.4457 0.418035 12.8231 0.779612C13.1819 1.12318 13.5154 1.70582 13.5154 2.3973V9.76597C13.5154 11.0339 12.5298 12 11.172 12H2.34531C1.65155 12 1.06666 11.7536 0.713916 11.403C0.510498 11.2008 0.369266 11.0501 0.235182 10.7861C0.121333 10.5622 0.00195312 10.2226 0.00195312 9.88347Z"
          fill="#304A79"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5703 8.55086V3.61238C14.5703 3.49555 14.8143 3.4012 15.0256 3.28532C15.2043 3.18731 15.3423 3.09661 15.521 2.99874C16.2735 2.58613 17.3515 1.90359 18.0329 1.56069C19.1807 0.982926 19.9992 1.93987 19.9992 2.63243V9.60906C19.9992 10.0927 19.5233 10.6686 18.9403 10.7811C18.278 10.9089 17.9 10.559 17.3414 10.2381L14.7114 8.72292C14.6337 8.67513 14.5703 8.65862 14.5703 8.55086Z"
          fill="#304A79"
        />
      </g>
      <defs>
        <clipPath id="clip0_941_578">
          <rect width={20} height="11.8367" fill="white" transform="matrix(1 0 0 -1 0 12)" />
        </clipPath>
      </defs>
    </svg>
  );
}
