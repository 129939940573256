import { Field, Form } from "react-final-form";
import s from "./LoginModal.module.scss";
import Button from "@/components/atoms/Button";
import FormGroup from "@/components/atoms/Form/FormGroup";
import TextInput from "@/components/atoms/Form/TextInput";
import { useRef, useState } from "react";
import cn from "clsx";
import OTPInput from "@/components/atoms/Form/OTPInput";

const COUNTER_SECONDS = 180;
const LoginwithOTP = ({setTab}) => {
  const [isSent, otpSend] = useState(false);
  const [isLoading, loading] = useState(false);
  const [message, setMessage] = useState(null);
  const _ref = useRef<any>({});
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(COUNTER_SECONDS);
  const countdownId = useRef(null);

  const resetTimer = (flag = false) => {
    if (flag) {
      setResendDisabled(true);
      setCountdown(COUNTER_SECONDS);
    }

    if (countdownId.current) {
      clearInterval(countdownId.current);
    }
    
    countdownId.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownId.current);
          setResendDisabled(false);
          return 0;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);
  };
  const sendOtp = (mobileOrEmail, timer = false) => {
    setMessage("");
    fetch("/api/login/sendotp", {
      method: "post",
      body: JSON.stringify(mobileOrEmail),
    })
      .then((d) => d.json())
      .then((d) => {
        if (d.success) {
          _ref.current = d;
          otpSend(true);
          if (timer) resetTimer(true);
        } else {
          setMessage(d.message);
        }
      })
      .catch((e) => {})
      .finally(() => {
        loading(false);
      });
  };

  const handleSubmit = (e, form) => {
    loading(true);
    if (isSent) {
      setMessage("");
      delete _ref.current.otp;
      fetch("/api/login/verifyotp", {
        method: "post",
        body: JSON.stringify({
          ..._ref.current,
          ...e,
        }),
      })
        .then((d) => d.json())
        .then((d) => {
          if (d.redirectUrl) {
            window.location.href = d.redirectUrl;
          } else {
            setMessage(d.message);
            form.reset({ otp: "", email: e.email });
          }
        })
        .catch((e) => {})
        .finally(() => {
          loading(false);
        });
    } else {
      sendOtp(e, true);
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: any = {};
        if (
          !/\d{10}/.test(values.email) &&
          !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)
        ) {
          errors.email = "Please enter valid email or mobile";
        }

        return errors;
      }}
    >
      {({ form, handleSubmit, hasValidationErrors }) => {
        const state = form.getState();
        const values = state.values;
        return (
          <form
            className={`${s.content} py-4 flex flex-col`}
            onSubmit={handleSubmit}
          >
            <div
              className={`text-blu font-baskervville font-normal capitalize mb-3 lg:mb-4 ${s.header}`}
            >
              Student Login
            </div>
            <div
              className={`text-sec font-railway font-medium mb-7 lg:mb-9 ${s.text}`}
            >
              Enter your details here to login to your account
            </div>
            <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-x-4 mb-9">
              <Button
                bg="o"
                type="button"
                buttonProps={{
                  onClick: ()=>setTab("withEmail"),
                }}
              >
                Login with email
              </Button>
              <Button
                bg="s"
                setTabtype="button"
                buttonProps={{
                  onClick: ()=>setTab("withOTP")
                }}
              >
                Login with otp
              </Button>
            </div>
            {message ? <div className="text-red-800">{message}</div> : null}
            <Field name="email">
              {({ input, meta }) => {
                meta.error && setMessage("");
                return (
                  <FormGroup
                    label="Email Id/ Phone No"
                    required
                    className={`font-railway ${isSent ? "hidden" : ""}`}
                    error={meta.touched && meta.error}
                  >
                    <TextInput
                      {...input}
                      placeholder="Enter email address or mobile number"
                    />
                  </FormGroup>
                );
              }}
            </Field>
            <div
              className={`flex w-min flex-wrap items-center justify-between ${
                isSent ? "" : "hidden"
              }`}
              style={{ width: "100%" }}
            >
              <span className="text-xs text-blu underline">
                Enter OTP sent to {values.email}
              </span>
              <span
                className="text-xs flex items-center whitespace-nowrap cursor-pointer"
                onClick={(e) => {
                  setMessage("");
                  otpSend(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  className="ml-auto"
                >
                  <g clipPath="url(#clip0_1165_4804)">
                    <path
                      d="M12.3025 7.8925L13.1075 8.6975L5.18 16.625H4.375V15.82L12.3025 7.8925ZM15.4525 2.625C15.2337 2.625 15.0062 2.7125 14.84 2.87875L13.2388 4.48L16.52 7.76125L18.1212 6.16C18.4625 5.81875 18.4625 5.2675 18.1212 4.92625L16.0738 2.87875C15.8988 2.70375 15.68 2.625 15.4525 2.625ZM12.3025 5.41625L2.625 15.0938V18.375H5.90625L15.5837 8.6975L12.3025 5.41625Z"
                      fill="#1A325D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1165_4804">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Edit Number</span>
              </span>
              <div className="w-100 mt-4" style={{ width: "100%" }}>
                <OTPInput
                  name="otp"
                  message={message}
                  key={isLoading && message}
                  loading={isLoading}
                />
              </div>
            </div>
            <div className="mb-2"></div>
            <div
              className={`${cn({
                ["grey"]: resendDisabled,
                ["text-blu underline"]: !resendDisabled,
              })} mb-4 text-xs inline-flex`}
            >
              {isSent ? (
                <>
                  <span
                    onClick={() => {
                      if (!resendDisabled) {
                        sendOtp(values);
                        resetTimer(true);
                        setResendDisabled(true);
                      }
                    }}
                    className={`${cn({
                      ["cursor-pointer"]: !resendDisabled,
                    })}`}
                  >
                    Resend OTP
                  </span>
                  {resendDisabled && (
                    <span style={{ marginLeft: 4 }}>
                      in{" "}
                      {Math.floor(countdown / 60)
                        .toString()
                        .padStart(2, "0")}{" "}
                      min {(countdown % 60).toString().padStart(2, "0")} sec
                    </span>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <Button
              className={`mt-auto`}
              type="submit"
              disabled={isLoading || hasValidationErrors}
            >
              SUBMIT
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

export default LoginwithOTP;
