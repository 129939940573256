export default function RightArrow({ className }) {
  return (
    <svg width={20} height={16} viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <g>
            <path
              className={className}
              d="M1 7.91907L18 7.91907"
              stroke="#FFF"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              className={className}
              d="M11.271 1.27179L17.8596 7.86042"
              stroke="#FFF"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
             className={className}
              d="M11.271 14.4493L17.8596 7.86065"
              stroke="#FFF"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
