import styles from "./header.module.scss";
import cn from "clsx";
import Image from "next/image";
import Button from "@/components/atoms/Button";
import Search1Logo from "@svgIcons/Search1";
import DownLtLogoBlue from "@svgIcons/DownIt";
import dynamic from "next/dynamic";
import { Suspense, useContext } from "react";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { ModalTypes } from "@/types/modal";
import { GA_EVENTS, GAEvent } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";

import { ModalContext } from "@/context/ModalContext"
import { IModalActions } from "@/types/modal";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";

const ProgramMenu = dynamic(() => import("@molecules/ProgramMenu/ProgramsMenu"));

export default function MobileMenu({
  menuOpen, 
	headerMenu, 
	programMenuOpen,
	setProgramMenuOpen,
	programMenuList,
	programDomains,
	SearchBox,
	results,
  associatedPrograms
}){
  const { LEAD_INITIATE, NAVIGATION_CLICK } = GAEvent;
  const { modalDispatch } = useContext(ModalContext);
  
  const handleGaClickEnroll = () => {
    pushToDataLayer({
      ...GA_EVENTS.get(LEAD_INITIATE),
      cta_text: headerMenu.CTA.text || "NA",
      section_name: "Header",
      header_title: "NA",
      page_type: getGlobalStateValue('pageType'),
      page_url : window?.location?.href || "NA"
    })
    updateStateSession({
      parentSection : "Header"
    })
    CTAHandaler();
    return;
  }

  const handleGaClick = (payload={}) => {
    pushToDataLayer({...payload});
  }

  const CTAHandaler = ()=>{
    modalDispatch({
          type: IModalActions.OpenModal,
          data: {
             modalType: ModalTypes.ConnectModal,
             modalData: { associatedPrograms, isLandingPage : true, },
          },
        })
  }



	return (
    <div
      className={cn(styles.mobileMenu, "flex flex-col items-start", {
        ["hidden"]: !menuOpen,
      })}
    >
      <div className={styles.btnContainerTop} >
        <div className={cn("cursor-pointer relative", styles.searchBox)}>
          <div className={cn("absolute", styles.searchIcon)}>
          <Search1Logo />
          </div>
          
          {SearchBox}
        </div>

        {!results?.length &&
          !programMenuOpen &&
          headerMenu?.MenuItem?.filter((x) => x.enabled)?.map(
            ({ Item, SubItem }, index) => (
              <div
                className={cn("flex items-center", styles.menuItem)}
                key={index}
              >
                <a
                  href={Item.url}
                  target={Item.target || "_self"}
                  key={Item.id}
                  className="flex justify-center items-center"
                  onClick={(e) => {
                    if (index == 0) {
                      e.preventDefault();
                      setProgramMenuOpen(true);
                    }else{
                      handleGaClick(
                        {
                          event : NAVIGATION_CLICK,
                          cta_text: Item?.text || "", 
                          category : Item?.title || "",
                          sub_category : "NA",
                          section_name : "hamburger",
                          redirection_url : Item?.url || "",
                          page_type : getGlobalStateValue('pageType')  || "Home Page",
                          header_title : "NA"
                        }
                      )
                    }
                  }}
                >
                  <span className="uppercase mr-3">{Item.text}</span>
                  {index == 0 && (
                    <DownLtLogoBlue />
                  )}
                </a>
              </div>
            ),
          )}
      </div>

      <div className={cn(styles.programMenu)}>
        <Suspense fallback={null}>
          <ProgramMenu
            isMobile
            goBack={() => {
              setProgramMenuOpen(false)
            }}
            programMenu={programMenuList}
            programDomains={programDomains}
            programMenuOpen={programMenuOpen}
          />
        </Suspense>
      </div>
      
      {!results?.length && !programMenuOpen && (
        <div
          className={cn(
            styles.btnContainer,
            "mt-auto flex flex-col items-start",
          )}
        >
          {headerMenu?.LoginCTA?.enabled && (
              <CommonButton
                modalType={ModalTypes.LoginModal}
                type={"button"}
                color={"b"}
                icon="/_a/icons/left-arrow.svg"
                hoverIcon="/_a/icons/left-arrow.svg"
                bg="o"
                className={cn(styles.mr)}
                imageLoadingStrategy="lazy"
              >
                {headerMenu.LoginCTA.text}
              </CommonButton>
          )}
          {headerMenu?.CTA?.enabled && (
            <Button
              buttonProps={{
                onClick:(e:any)=>{ handleGaClickEnroll() },
              }}
              bg="s"
              color="y"
              icon="/_a/icons/left-arrow.svg"
            >
              {headerMenu.CTA?.text}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

