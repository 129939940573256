"use client";

import s from "./Form.module.scss";

export default function FormGroup({
  label,
  id = null,
  required = false,
  children = null,
  helperContent = null,
  error = null,
  className = "",
}: any) {
  return (
    <div className={`${className} ${s.group}`}>
      {label ? (
        <label
          className="text-blu"
          id={`${id}-label`}
          htmlFor={`${id}-control`}
        >
          {label} {required ? " *" : ""}
        </label>
      ) : null}
      <div className={s.control}>{children}</div>
      {error ? <div className={s.error}>{error}</div> : null}
      {helperContent ? <div className={s.helper}>{helperContent}</div> : null}
    </div>
  );
}
