import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss';

export default function IntersectionObserverView({ children }) {
    
    const [isIntersecting, setIntersecting] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) =>
        entry.isIntersecting ? setIntersecting(entry.isIntersecting) : null
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, []);

    return <div className={styles.intersectionDiv} ref={ref}>{isIntersecting ? children : null }</div>
}
