import { Domain } from "@/components/common/common.type";

export const COUNTRY_CALLING_CODES = [
  "+91",
  "+7840",
  "+93",
  "+355",
  "+213",
  "+1684",
  "+376",
  "+244",
  "+1264",
  "+1268",
  "+54",
  "+374",
  "+297",
  "+247",
  "+61",
  "+672",
  "+43",
  "+994",
  "+1242",
  "+973",
  "+880",
  "+1246",
  "+375",
  "+32",
  "+501",
  "+229",
  "+1441",
  "+975",
  "+591",
  "+387",
  "+267",
  "+55",
  "+246",
  "+1284",
  "+673",
  "+359",
  "+226",
  "+257",
  "+855",
  "+237",
  "+1",
  "+238",
  "+345",
  "+236",
  "+235",
  "+56",
  "+86",
  "+57",
  "+269",
  "+242",
  "+243",
  "+682",
  "+506",
  "+385",
  "+53",
  "+599",
  "+537",
  "+420",
  "+45",
  "+253",
  "+1767",
  "+1809",
  "+593",
  "+20",
  "+503",
  "+240",
  "+291",
  "+372",
  "+251",
  "+298",
  "+679",
  "+358",
  "+33",
  "+594",
  "+689",
  "+241",
  "+220",
  "+995",
  "+49",
  "+233",
  "+350",
  "+30",
  "+299",
  "+1473",
  "+590",
  "+1671",
  "+502",
  "+224",
  "+245",
  "+509",
  "+504",
  "+852",
  "+36",
  "+354",
  "+62",
  "+98",
  "+964",
  "+353",
  "+972",
  "+39",
  "+225",
  "+1876",
  "+81",
  "+962",
  "+77",
  "+254",
  "+686",
  "+965",
  "+996",
  "+856",
  "+371",
  "+961",
  "+266",
  "+231",
  "+218",
  "+423",
  "+370",
  "+352",
  "+853",
  "+389",
  "+261",
  "+265",
  "+60",
  "+960",
  "+223",
  "+356",
  "+692",
  "+596",
  "+222",
  "+230",
  "+262",
  "+52",
  "+691",
  "+1808",
  "+373",
  "+377",
  "+976",
  "+382",
  "+1664",
  "+212",
  "+95",
  "+264",
  "+674",
  "+977",
  "+31",
  "+1869",
  "+687",
  "+64",
  "+505",
  "+227",
  "+234",
  "+683",
  "+850",
  "+1670",
  "+47",
  "+968",
  "+92",
  "+680",
  "+970",
  "+507",
  "+675",
  "+595",
  "+51",
  "+63",
  "+48",
  "+351",
  "+1787",
  "+974",
  "+40",
  "+7",
  "+250",
  "+685",
  "+378",
  "+966",
  "+221",
  "+381",
  "+248",
  "+232",
  "+65",
  "+421",
  "+386",
  "+677",
  "+27",
  "+500",
  "+82",
  "+34",
  "+94",
  "+249",
  "+597",
  "+268",
  "+46",
  "+41",
  "+963",
  "+886",
  "+992",
  "+255",
  "+66",
  "+670",
  "+228",
  "+690",
  "+676",
  "+1868",
  "+216",
  "+90",
  "+993",
  "+1649",
  "+688",
  "+1340",
  "+256",
  "+380",
  "+971",
  "+44",
  "+598",
  "+998",
  "+678",
  "+58",
  "+84",
  "+681",
  "+967",
  "+260",
  "+263",
];

export const COUNTRY_NAMES_LIST = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts & Nevis",
  "Saint Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis & Futuna",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const SOCIAL_MEDIA_LIST = [
  "Instagram",
  "Facebook",
  "X",
  "Youtube",
  "LinkedIn"
];

export const LANGUAGES={
  "x-default": "https://amityonline.com",
  "en-IN": "https://amityonline.com",
  "en-CA": "https://amityonline.com",
  "en-US": "https://amityonline.com",
  "en-AE": "https://amityonline.com",
  "en-BH": "https://amityonline.com",
  "en-KW": "https://amityonline.com",
  "en-OM": "https://amityonline.com",
  "en-SA": "https://amityonline.com",
  "en-QA": "https://amityonline.com",
  "en-NP": "https://amityonline.com",
  "en-BT": "https://amityonline.com",
  "en-LK": "https://amityonline.com",
  "en-ID": "https://amityonline.com",
  "en-BD": "https://amityonline.com",
  "en-NG": "https://amityonline.com",
  "en-PH": "https://amityonline.com",
  "en-UG": "https://amityonline.com",
  "en-ZM": "https://amityonline.com",
  "en-ZW": "https://amityonline.com",
  "en-YE": "https://amityonline.com",
  "en-VN": "https://amityonline.com",
  "en-TZ": "https://amityonline.com",
  "en-SD": "https://amityonline.com",
  "en-SY": "https://amityonline.com",
  "en-SO": "https://amityonline.com",
  "en-RW": "https://amityonline.com",
  "en-MM": "https://amityonline.com",
  "en-LS": "https://amityonline.com",
  "en-LA": "https://amityonline.com",
  "en-KE": "https://amityonline.com",
  "en-IQ": "https://amityonline.com",
  "en-GH": "https://amityonline.com",
  "en-ER": "https://amityonline.com",
  "en-ET": "https://amityonline.com",
  "en-KH": "https://amityonline.com",
  "en-AO": "https://amityonline.com",
  "en-MV": "https://amityonline.com",
  "en-AF": "https://amityonline.com",
  "en-GB": "https://amityonline.com",
  "en-SG": "https://amityonline.com",
  "en-DE": "https://amityonline.com",
  "en-MY": "https://amityonline.com",
  "en-PK": "https://amityonline.com",
  "en-AU": "https://amityonline.com",
  "en-ZA": "https://amityonline.com",
  "en-TH": "https://amityonline.com",
  "en-CN": "https://amityonline.com",
  "en-JP": "https://amityonline.com",
  "en-HK": "https://amityonline.com",
  "en-FR": "https://amityonline.com",
  "en-MW": "https://amityonline.com",
}


export const debugBearScript =  {
  "beta-fe.amityonline.com": "https://cdn.debugbear.com/1guR4fYUYw03.js",
  "dev-amity.univoedtech.in": "https://cdn.debugbear.com/W5sz8kP2cncJ.js",
  "preprod.amityonline.com": "https://cdn.debugbear.com/a4SRWaTP0tTL.js"
}

export const domain: Record<Domain, Domain> = {
  "beta-fe.amityonline.com": "beta-fe.amityonline.com",
  "dev-amity.univoedtech.in": "dev-amity.univoedtech.in"
}