"use client";

import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";

import styles from "./AccreditionsRecognitions.module.scss";

const QSRanking = dynamic(() => import("@/SvgIcons/QSRanking"), {
  ssr: false,
});
const WASC = dynamic(() => import("@/SvgIcons/WASC"), { ssr: false });

const WorldUniversityRanking = dynamic(() => import("@/SvgIcons/WorldUniversityRanking"), {
  ssr: false,
});
const AICTE = dynamic(() => import("@/SvgIcons/AICTE"), { ssr: false });
const DigitalEducationCouncil = dynamic(() => import("@/SvgIcons/DigitalEducationCouncil"), {
  ssr: false,
});
const WES = dynamic(() => import("@/SvgIcons/WES"), { ssr: false });

const NCHE = dynamic(() => import("@/SvgIcons/NCHE"), { ssr: false });
const UGC = dynamic(() => import("@/SvgIcons/UGC"), { ssr: false });

const SVG_List = [
  { comp: QSRanking, text: "India's only Online MBA ranked by QS: Asia Pacific Top 10" },
  {
    comp: UGC,
    text: "First university in India to receive UGC approval for Online Degrees and Learning Platform",
  },
  { comp: WASC, text: "The Western Association of Schools and Colleges" },
  { comp: WES, text: "WES accredited in Canada" },
  { comp: AICTE, text: "Degree programs approved by All India Council of Technical Education" },
  { comp: DigitalEducationCouncil, text: "India's first private university to be a member" },
  { comp: WorldUniversityRanking, text: "Ranked among Top 3% Globally" },
  {
    comp: NCHE,
    text: "First Indian University to be accredited by National Council for Higher Education, Africa",
  },
];

export default function ClientWrapperIntersectionObserver({ index }) {
  const SVGLIST = () => {
    const Comp = SVG_List[index]["comp"];

    return <Comp />;
  };

  const ref = useRef(null);

  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      entry.isIntersecting ? setIntersecting(entry.isIntersecting) : null
    );
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className={styles.svgContainer} ref={ref}>
        {isIntersecting && <SVGLIST />}
      </div>
      <p className="text-sec font-raleway">{SVG_List[index]["text"]}</p>
    </>
  );
}
