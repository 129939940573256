import { Field } from "react-final-form";
import s from "./OTPInput.module.scss";
import { ChangeEventHandler, useRef, useId, useEffect } from "react";

function OTPInputView({ value, onChange, className, message, loading }) {
  const ref = useRef<HTMLDivElement>(null);
  const id = useId();
  const handleKeydown = (e) => {
    if (e.key == "Backspace") {
      let el = document.getElementById(`otp_${id}_${value.length - 1}`);
      if (el) {
        el.focus();
      }
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
    let _index = Number(e.currentTarget.dataset.otpindex);
    let _value = String(e.currentTarget.value);
    let arr = value.split("");
    if (_value === "") {
      // when removing the value at index we need to remove the value at index
      arr.splice(_index, 1);
    } else {
      // when adding the value at index we need to add the value at index without removing the value at index
      arr.splice(_index, 0, _value.toString());
    }
    onChange(arr.join(""));
  };

  useEffect(() => {
    if (message && !loading) {
      let el = ref.current;
      if (el) {
        let inputs = el.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].value = "";
        }
      }
    }
  }, [message]);

  useEffect(() => {
    let el = document.getElementById(`otp_${id}_${value.length}`);
    if (el) {
      el.focus();
    }
  }, [value]);

  return (
    <div className={`${s.root} ${className}`} ref={ref}>
      <input
        id={`otp_${id}_0`}
        maxLength={1}
        data-otpindex={0}
        className={s.input}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        disabled={loading}
      />
      <input
        id={`otp_${id}_1`}
        maxLength={1}
        data-otpindex={1}
        className={s.input}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        disabled={loading || value.length < 1}
      />
      <input
        id={`otp_${id}_2`}
        maxLength={1}
        data-otpindex={2}
        className={s.input}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        disabled={loading || value.length < 2}
      />
      <input
        id={`otp_${id}_3`}
        maxLength={1}
        data-otpindex={3}
        className={s.input}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        disabled={loading || value.length < 3}
      />
    </div>
  );
}

export default function OTPInput(props) {
  return (
    <Field
      name={props.name}
      render={({ input: { value, onChange } }) => {
        return (
          <OTPInputView
            value={value}
            onChange={onChange}
            className={props.className}
            message={props.message}
            loading={props.loading}
          />
        );
      }}
    />
  );
}
