"use client";

import Checkbox from "@/components/atoms/FormComponents/Checkbox";
import Input from "@/components/atoms/FormComponents/Input/Input";
import Select from "@/components/atoms/FormComponents/Select";
import SubmitButton from "@/components/atoms/FormComponents/SubmitButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "clsx";
import styles from "./LandingPageConnectModalFormTempleteOne.module.scss";
import { trackingDetails } from "@/api/getOtherFormDetails";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { COUNTRY_CALLING_CODES } from "@/helpers/constants";
import { useOTPContext } from "@/components/sections/CommonModals/context";
import { PROGRAM_DROPDOWN, PROGRAM_DROPDOWN_LP, DEGREE_TYPES } from "../../ProgramMenu/constants";
import { GAEvent, GA_EVENTS, GA_EVENTS_PAYLOAD } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGAPayload } from "@/helpers/getGAPayload";
import { useQueryParams } from "@/helpers/useQueryParams";

import { SELECT_DEGREE, getProgramId, filterSelectedPrograms, precedentSelectedPrograms, SELECT_PROGRAM } from "../ConnectModalForm/utils";
import { StorageService } from "@/helpers/storage.service";
import { getGlobalStateValue } from "@/context/utils";
import { disabledButtonPaths } from "../LandingPageConnectModalForm/utils";

const degreeOptions = ["UG", "PG"];

const excludeProgramsOutsideIndia = [
  "BACHELOR OF ARTS (GENERAL) HINDI MEDIUM",
  "BACHELOR OF ARTS (GENERAL) IN KANNADA",
  "BACHELOR OF ARTS (GENERAL) IN MALAYALAM",
  "BACHELOR OF ARTS (GENERAL) IN TAMIL",
  "BACHELOR OF ARTS (GENERAL) IN TELUGU",
  "BACHELOR OF ARTS (HINDI MEDIUM)",
  "BACHELOR OF COMMERCE (GENERAL) HINDI MEDIUM",
  "BACHELOR OF COMMERCE (GENERAL) IN KANNADA",
  "BACHELOR OF COMMERCE (GENERAL) IN MALAYALAM",
  "BACHELOR OF COMMERCE (GENERAL) IN TAMIL",
  "BACHELOR OF COMMERCE (GENERAL) IN TELUGU",
];

PROGRAM_DROPDOWN.sort((a, b) => a.title.localeCompare(b.title));
PROGRAM_DROPDOWN_LP.sort((a, b) => a.title.localeCompare(b.title));

const errorAccordingToFieldName = {
  fullName: "Please enter valid full name",
  countryCode: "Please enter valid country code",
  phoneNumber: "Please enter valid phone number",
  emailId: "Please enter valid email address",
  degree: "Please select a degree",
  program: "Please select a program",
  isTermsAccepted: "Please accept terms & conditions",
};

const exlcudePrograms = [
	"BSc. (Hons.) in Information Technology",
	"MSc. in Information Technology",
	"MSc. Environmental Science",
];

const programsOptions = PROGRAM_DROPDOWN.filter(
  (e) => !exlcudePrograms.includes(e.title)
)
  .filter(
    (x: any) =>
      x.programType &&
      degreeOptions.indexOf(String(x.programType).toUpperCase()) > -1
  )
  .map((x: any) => x.title);
const countryCodeOptions = COUNTRY_CALLING_CODES;

const CODE = "Code";
	
export default function LandingPageConnectModalFormTempleteOne(props: {
	setMessage?: any
	isOtpValidated ?: (x:any, y?:any) => void
	setFormResponse?: (x: any) => void
	gaOtherPayloadInfo?: any
	setFormValuesData?:(x: any) => void
	isLandingPage?: boolean,
	associatedPrograms?: Array<ProgramDetails> ,
	isNewLeadForm?: boolean,
	setLeadFailed?: (x:boolean) => void,
	isLambdaAPI?: boolean
}) {
	const {
    setFormValuesData = () => {},
    gaOtherPayloadInfo = {},
    isLandingPage = false,
    associatedPrograms = [],
	isNewLeadForm = false,
	setLeadFailed,
	isLambdaAPI = false
  } = props;

	const path = usePathname();

	const searchParams = useSearchParams();

	const prospectIdRef = useRef(null);
    const programListFinal = isLandingPage
      ? precedentSelectedPrograms(associatedPrograms)
      : programsOptions
	const [programsList, setProgramsList] = useState(programListFinal)
	const [message, setMessage] = useState(null)
	const [isLoading, loading] = useState(false)
	const { handleSendOTP, isOTPLoading, setLeadResponse } = useOTPContext()
	const [ipStackDetails, setIpStackDetails] = useState(null)
	const [formValues, setFormValues] = useState({
		fullName: "",
		countryCode: "+91",
		phoneNumber: "",
		emailId: "",
		degree: SELECT_DEGREE,
		program: "Select Program",
		isTermsAccepted: true,
	})
	const specializationKey = useRef<string>('');
	const custom10PageTypeKey = useRef<string>('');
	const [errors, setErrors] = useState({
		fullName: "",
		countryCode: "",
		phoneNumber: "",
		emailId: "",
		degree: "",
		program: "",
		isTermsAccepted: "",
	})
	const [isFormValid, setIsFormValid] = useState(false)
	const [showProgramField, setshowProgramField] = useState(false);
	
	const regexForInputs: any = {
		fullName: /^[a-zA-Z][a-zA-Z ]{2,49}$/,
		countryCode: /^\+(?:[0-9]?){6,14}[0-9]$/,
		phoneNumber: /^[1-9][0-9]{9}$/,
		emailId: /^[a-zA-Z0-9+_.-]+@(?:[a-zA-Z0-9.-]+\.)+([a-zA-Z]{2,})$/,
		degree: new RegExp("^(" + degreeOptions.join("|") + ")$"),
		program: new RegExp("^(" + programListFinal.join("|") + ")$"),
		isTermsAccepted: /^true$/,
	}
	const { HERO_CARD_SUBMIT_DETAILS, HERO_CARD_SUBMIT_OTP, LEAD_SUBMIT } = GAEvent
	const handlePhoneNumberChange = (
		value: string,
		countryCodeVal: string = formValues.countryCode
	) => {
		let regexToUse = regexForInputs["phoneNumber"]
		if (countryCodeVal != "+91") {
			regexToUse = /^[1-9][0-9]{6,14}$/
		}
		if (!regexToUse.test(value)) {
			setErrors((prev) => ({
				...prev,
				phoneNumber: errorAccordingToFieldName["phoneNumber"],
			}))
		} else {
			setErrors((prev) => ({ ...prev, phoneNumber: "" }))
		}
		setFormValues((prev) => ({ ...prev, phoneNumber: value }))
	}

	const updateCountryCode = (value) => {
		const newFormValues  = { ...formValues }
		newFormValues['countryCode'] = value;
		newFormValues['phoneNumber'] = "";
		setshowProgramField(false)
		setFormValues(newFormValues);
	}

	const handleSingleFieldChange = (
		fieldName: string,
		value: string | boolean
	) => {
		let errors = "";
		if (
			(!regexForInputs[fieldName]?.test(value) &&
				fieldName !== "degree" &&
				fieldName !== "program") ||
			value.toString().includes("Select")
		) {
			errors = errorAccordingToFieldName[fieldName];
			setErrors((prev) => ({
				...prev,
				[fieldName]: errorAccordingToFieldName[fieldName],
			}))
		} else {
			setErrors((prev) => ({ ...prev, [fieldName]: "" }))
		}
		setFormValues((prev) => ({ ...prev, [fieldName]: value }))

		if (fieldName === "countryCode") {
			handlePhoneNumberChange(formValues.phoneNumber, value.toString())
		}

		if (fieldName === "degree") {
			setFormValues((prev) => ({ ...prev, program: SELECT_PROGRAM }))
			handleFieldBlur("degree", typeof value==='string' && value, errors);
		}

        if (fieldName === "program"){
            handleFieldBlur("program", typeof value==='string' && value, errors);
        }

		if (fieldName == "program" && isLandingPage){
			const selectedProgram = associatedPrograms.find(
        (obj) => obj.attributes.BasicDetails?.Heading?.toLocaleUpperCase() === value
      );

      	if (selectedProgram) {
       	 specializationKey.current =
        	  selectedProgram.attributes.BasicDetails?.SpecializationText || "";
			custom10PageTypeKey.current =
					selectedProgram.attributes.BasicDetails?.Custom10PgType || "";
     	}	
			  

     	}

	}

	const checkFieldsValid = () => {
		return (
			formValues.fullName !== "" &&
			formValues.phoneNumber !== "" &&
			formValues.emailId !== "" &&
			formValues.isTermsAccepted &&
			formValues.countryCode !== CODE && 
			Object.values(errors).every((x) => x === "")
		)
	}

	const checkAllFieldsValid = () => {
		return (
			formValues.fullName.trim() !== "" &&
			formValues.phoneNumber.trim() !== "" &&
			formValues.emailId.trim() !== "" &&
			formValues.degree !== "" && 
			formValues.program !== SELECT_PROGRAM  &&
			formValues.isTermsAccepted === true &&
			formValues.countryCode !== CODE && 
			Object.values(errors).every((error) => error === "")
		);
	}
	const checkLeadFail = (status:boolean) =>{
		setLeadFailed(status)
	}

	const handleLeadCheck = (payload) => {

		setFormValuesData(formValues);

		const form = {
			name: payload.fullName,
			phone: payload.phoneNumber,
			countryCode: payload.countryCode,
			email: payload.emailId,
			program: payload.program,
			PROGRAM_ID: getProgramId({ associatedPrograms, program: payload.program, isLandingPage  }),
		    degree: "",
			consent: true,
		}

		const tracking = trackingDetails();
		const leadSquareApiCall = async (payload) => {
			return fetch("/api/leadsquared.com/lead-create", {
				method: "POST",	
				headers: {
				'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				ipstack: ipStackDetails,
				form : {...form,...payload},
				meta: {
					...tracking,
					utm_medium: tracking?.utm_medium || "request_a_callback",
				},
				}),
			});
		};
		const submitGaCb = () => {
			const gaData:any = {
				...GA_EVENTS_PAYLOAD.get(HERO_CARD_SUBMIT_DETAILS),
				enhanced_conversion : {
					emailId : formValues?.emailId || "",
					phoneNumber : formValues?.phoneNumber || "",
				},
				enhanced_conversion_data : {
					emailId : formValues?.emailId || "",
				}
			}
			const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
			pushToDataLayer(gaPayload);
		}
			
		const verifyOtpGaCb = async ()=>{
			if(path==="/contact-us" && payload?.countryCode === "+91"){
				window.location.href = "/thankyou";
			}
            setFormValues({
                fullName: "",
                countryCode: "+" + ipStackDetails?.location?.calling_code || "91",
                phoneNumber: "",
                emailId: "",
                degree: SELECT_DEGREE,
                program: "",
                isTermsAccepted: true,
            })
			const gaData:any = {
				...GA_EVENTS_PAYLOAD.get(HERO_CARD_SUBMIT_OTP),
				enhanced_conversion : {
					emailId : formValues?.emailId || "",
					phoneNumber : formValues?.phoneNumber || "",
				},
				enhanced_conversion_data : {
					emailId : formValues?.emailId || "",
				}
			} ;
			const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
			pushToDataLayer(gaPayload);
		
			props?.isOtpValidated(true, payload.emailId);
			
		}

		const cb = () => {
			return new Promise((resolve, reject) => {
			  loading(true);
			  const connectCounsellorApiCall = (status:boolean)=> fetch("/api/connect-counsellor", {
				method: "POST",
				body: JSON.stringify({
				  ipstack: ipStackDetails,
				  form,
				  meta: {
					...tracking,
					utm_medium: tracking?.utm_medium || "request_a_callback",
				  },
				  status
				}),
			  });

				const setCounselorData = async (status:boolean) => {
					try{
						const counsellorResponse = await connectCounsellorApiCall(status);
						const counsellorData = await counsellorResponse.json();
						return counsellorData
					}catch(err){
						console.error("Error in councelor udpate data", err)
					}
				}
		  
				async function performApiCalls() {
					try {
						loading(true);
						let leadSquareResponse;
						try {
							const response = await leadSquareApiCall({ Custom5: "Not Verified" });
							if (typeof response === 'string') {
								leadSquareResponse = JSON.parse(response);
							} else {
								leadSquareResponse = await response.json();

								fetch("/api/prospect-activity",{
									method: "post",
									body: JSON.stringify({ leadResponse: leadSquareResponse.response }),
								  }).then(res=> {
									// do nothing
								  })
							}
							if (leadSquareResponse?.success) {
								setIsFormValid(false);
								const resp = leadSquareResponse?.response || {}
								
									pushToDataLayer({
										event  : "lead_submit",
										cta_text : "Submit",
										lead_starting_cta : getGlobalStateValue('leadStartingCta') || "NA",
										lead_starting_url : window.location.href || "NA",
										lead_id : resp?.ProspectId || "NA",
										section_name : getGlobalStateValue('parentSection') || "NA",
										status : "success",
										error_message : "NA",
										page_type : getGlobalStateValue('pageType'),
										lead_type : "lead_submit_1",
										email_id: form.email,
										page_url : window?.location?.href || "NA"
									});
							

								if (resp.ID === '1') {
									props.isOtpValidated(false);
									setshowProgramField(true);
									return;
								}
	
								if (props.setFormResponse) {
									props.setFormResponse({
										ID: resp?.ID,
										link: resp?.URL,
										ProspectId: resp?.ProspectId,
									});
								}
								if (typeof setLeadResponse === "function") {
									setLeadResponse(resp);
								}
								props.setMessage(
									`Your request for counselling has been sent ${leadSquareResponse?.isDuplicate ? "already" : ""
									}, and our counsellor will reach out to you in the next few hours.`
								);
	
								resolve(resp);
							}
						} catch (error) {
							console.error("LeadSquare API call failed:", error);
							setIsFormValid(false);
							const counselorApiData = await setCounselorData(false)
							setshowProgramField(true);
							return;
						}
						if(!leadSquareResponse?.success){
							setIsFormValid(false);
							const counselorApiData = await setCounselorData(false)
							setshowProgramField(true);
							return;
						}
						resolve(leadSquareResponse?.response);

					} catch (error) {
						const errorMessage = "Error occurred.";
						
							pushToDataLayer({
								event  : "lead_submit",
								cta_text : "Submit",
								lead_starting_cta : getGlobalStateValue('leadStartingCta') || "NA",
								lead_starting_url : window.location.href || "NA",
								lead_id : "NA",
								section_name : getGlobalStateValue('parentSection') || "NA",
								status : "fail",
								error_message : error || "NA",
								page_type : getGlobalStateValue('pageType'),
								lead_type : "lead_submit_1",
								page_url : window?.location?.href || "NA"
							});
					

						setMessage(errorMessage);
						reject(error);
					} finally {
						loading(false);
						if(payload?.countryCode !== "+91"){
							props?.isOtpValidated(true, payload.emailId);
						}						
					}
				}
				performApiCalls();
			});
		  };
		  handleSendOTP(form, cb, submitGaCb, verifyOtpGaCb, checkLeadFail)
	}

	const handleSubmit = (payload) => {
		setFormValuesData(formValues);
		const form = {
			name: payload.fullName,
			phone: payload.phoneNumber,
			countryCode: payload.countryCode,
			email: payload.emailId,
			program: payload.program,
			PROGRAM_ID: getProgramId({ associatedPrograms, program: payload.program, isLandingPage  }),
		    degree: DEGREE_TYPES[payload.degree].name,
			consent: true,
		}

		if(isLandingPage){
			form['Custom6'] = specializationKey.current || '';
		}
	
		
		const tracking = trackingDetails();
		const leadSquareApiCall = async (payload) => {
				return fetch("/api/leadsquared.com/lead-create", {
					method: "POST",
					headers: {
					  'Content-Type': 'application/json',
					},
					body: JSON.stringify({
					  ipstack: ipStackDetails,
					  form : {...form,...payload},
					  meta: {
						...tracking,
						utm_medium: tracking?.utm_medium || "request_a_callback",
					  },
					}),
				  });
		}

		  const leadSquareApiCallLpPage = async (payload : any) => fetch("/api/leadsquared.com/lead-create-lp", {
			method: "POST",
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			  ipstack: ipStackDetails,
			  form : {...form,...payload},
			  meta: {
				...tracking,
				utm_medium: tracking?.utm_medium || "request_a_callback",
			  },
			}),
		  });

		
		const submitGaCb = () => {
			const gaData:any = {
				...GA_EVENTS_PAYLOAD.get(HERO_CARD_SUBMIT_DETAILS),
				enhanced_conversion : {
					emailId : formValues?.emailId || "",
					phoneNumber : formValues?.phoneNumber || "",
				},
				enhanced_conversion_data : {
					emailId : formValues?.emailId || "",
				}
			}
			const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
			pushToDataLayer(gaPayload);
		}
			
		const verifyOtpGaCb = async ()=>{
			if(path==="/contact-us" && payload?.countryCode === "+91"){
				window.location.href = "/thankyou";
			}
            setFormValues({
                fullName: "",
                countryCode: "+" + ipStackDetails?.location?.calling_code || "91",
                phoneNumber: "",
                emailId: "",
                degree: SELECT_DEGREE,
                program: "Select Program",
                isTermsAccepted: true,
            })
			const gaData:any = {
				...GA_EVENTS_PAYLOAD.get(HERO_CARD_SUBMIT_OTP),
				enhanced_conversion : {
					emailId : formValues?.emailId || "",
					phoneNumber : formValues?.phoneNumber || "",
				},
				enhanced_conversion_data : {
					emailId : formValues?.emailId || "",
				}
			} ;
			const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
			pushToDataLayer(gaPayload);
			props?.isOtpValidated(true, payload.emailId);
		}

		const cb = () => {
			return new Promise((resolve, reject) => {
			  loading(true);
			  const connectCounsellorApiCall = (status:boolean)=> fetch("/api/connect-counsellor", {
				method: "POST",
				body: JSON.stringify({
				  ipstack: ipStackDetails,
				  form,
				  meta: {
					...tracking,
					utm_medium: tracking?.utm_medium || "request_a_callback",
				  },
				  status
				}),
			  });
				
			  function getEvent(event) {
					StorageService.set("mFitlerKeyDetails", event.detail)
				}
				
				const setCounselorData = async (status:boolean) => {
					try{
						const counsellorResponse = await connectCounsellorApiCall(status);
						const counsellorData = await counsellorResponse.json();
						return counsellorData
					}catch(err){
						console.error("Error in councelor udpate data", err)
					}
				}

				async function performApiCalls() {
					try {
						loading(true);
						let leadSquareResponse;
						try {
							let response: any
							if(isLambdaAPI)
							{
								response = await leadSquareApiCallLpPage({ Custom5: "Not Verified", Custom10: custom10PageTypeKey.current || "" });
							} else {
								response = await leadSquareApiCall({ Custom5: "Not Verified", Custom10: custom10PageTypeKey.current || "" });
							}
							if (typeof response === 'string') {
								leadSquareResponse = JSON.parse(response);
							} else {
								leadSquareResponse = await response.json();

								fetch("/api/prospect-activity",{
									method: "post",
									body: JSON.stringify({ leadResponse: leadSquareResponse.response }),
								  }).then(res=> {
									// do nothing
								  })
							}
							if (leadSquareResponse?.success) {
								const resp = leadSquareResponse?.response || {};
								if (props.setFormResponse) {
									props.setFormResponse({
										ID: resp?.ID,
										link: resp?.URL,
										ProspectId: resp?.ProspectId,
									});
								}
								pushToDataLayer({
									"LSQ_ID" : resp?.ProspectId
								});
								pushToDataLayer({
									"event" : "MFilter_OTP_Verified_New",
									"LSQ_ID" : resp?.ProspectId
								})
								pushToDataLayer({
									event  : "lead_submit",
									cta_text : "Submit",
									lead_starting_cta : getGlobalStateValue('leadStartingCta') || "NA",
									lead_starting_url : window.location.href || "NA",
									lead_id : resp?.ProspectId || "NA",
									section_name : getGlobalStateValue('parentSection') || "NA",
									status : "success",
									error_message : "NA",
									page_type : getGlobalStateValue('pageType'),
									lead_type : "lead_submit_2",
									email_id: form.email,
									page_url : window?.location?.href || "NA"
								})
								document.addEventListener("mfilterit:botDetect", getEvent)
								StorageService.set("LSQ_ID", resp?.ProspectId)
								
								prospectIdRef.current = resp?.ProspectId;

								if (typeof setLeadResponse === "function") {
									setLeadResponse(resp);
								}
								props.setMessage(
									`Your request for counselling has been sent ${leadSquareResponse?.isDuplicate ? "already" : ""
									}, and our counsellor will reach out to you in the next few hours.`
								);
								if (payload?.countryCode !== "+91") {
									props?.isOtpValidated(true, payload.emailId);
								}
	
								resolve(resp);
							}
						} catch (error) {
							console.error("LeadSquare API call failed:", error);
							const counselorApiData = await setCounselorData(false)
						}
						if(!leadSquareResponse?.success){
							if (payload?.countryCode !== "+91") {
								props?.isOtpValidated(true);
							}
							const counselorApiData = await setCounselorData(false)
							resolve(leadSquareResponse?.success);
						}
					} catch (error) {
						console.log("Error:", error);
						const errorMessage = "Error occurred.";
							pushToDataLayer({
								event  : "lead_submit",
								cta_text : "Submit",
								lead_starting_cta : getGlobalStateValue('leadStartingCta') || "NA",
								lead_starting_url : window.location.href || "NA",
								lead_id : "NA",
								section_name : getGlobalStateValue('parentSection') || "NA",
								status : "fail",
								error_message : error,
								page_type : getGlobalStateValue("pageType") || "Home Page",
								lead_type : "lead_submit_2",
								email_id : form.email,
								page_url : window?.location?.href || "NA"
							})
						setMessage(errorMessage);
						reject(error);
					} finally {
						loading(false);
					}
				}
				performApiCalls();
			});
		  };

		handleSendOTP(form, cb, submitGaCb, verifyOtpGaCb, checkLeadFail)
	}


	const makeFormSubmission = useCallback(
		(e: any) => {
			e.preventDefault()
			const valid = checkFieldsValid()
			const isValid = checkAllFieldsValid()
		
			if(path === '/' || isNewLeadForm ){
				if(!showProgramField && valid ){
					handleLeadCheck(formValues)
				} else if (isValid){
					handleSubmit(formValues);
				}
			} else {
				isValid && handleSubmit(formValues);
			}
		},
		[formValues]
	)


	const fetchCurrentCountryCode = async () => {
		const data = await fetch(
			"https://api.ipstack.com/check?access_key=408eb9d56e1557ef60ace016db363652&format=1"
		)
		const jsonData = await data.json()
		setIpStackDetails(jsonData)
		setFormValues((prev) => ({
			...prev,
			countryCode: "+" + jsonData?.location?.calling_code || "91",
		}))
	}
	useEffect(() => {
		;(async function () {
			await fetchCurrentCountryCode()
		})()
	}, [])

	useEffect(() => {
		if(path === '/' || isNewLeadForm){
		if(!showProgramField){
		const isFormValid = checkFieldsValid()
		setIsFormValid(isFormValid)
		} else if(showProgramField){
			const isFormValid =  checkAllFieldsValid()
	        setIsFormValid(isFormValid)
		}
	} else {
		   const isFormValid =  checkAllFieldsValid()
	        setIsFormValid(isFormValid);
	}
	}, [formValues, setIsFormValid])


	useEffect(() => {

		if(isLandingPage){
			const newProgramList =  filterSelectedPrograms({ associatedPrograms , degree : formValues?.degree }) 
			setProgramsList(precedentSelectedPrograms(newProgramList))
		} else {
			const list = PROGRAM_DROPDOWN.filter(
				(x: any) => x.programType == formValues?.degree
			).map((x: any) => x.title)
	
			let finalProgramsList = list?.length ? list : programListFinal
	
			if (ipStackDetails?.country_code !== "IN") {
				finalProgramsList = finalProgramsList.filter(
					(x: any) => !excludeProgramsOutsideIndia.includes(x)
				)
			}
			setProgramsList(finalProgramsList)
		}
	}, [formValues?.degree, ipStackDetails])
	
	const handleFieldBlur = (fieldName: string, value: string, error: string) => {
		pushToDataLayer({
			'event': 'form_filled',
			'field_name': fieldName,
			'option_selected': value || "NA",
			'error_message': error || 'NA',
			'section_name': getGlobalStateValue('parentSection'),
			'page_type': getGlobalStateValue('pageType') || "Home Page",
			'page_url' : window?.location?.href || "NA"
		});
	};

	return (
    <>
      <form onSubmit={makeFormSubmission} className={cn(styles.root)}>
        <div className={cn(styles.inputWrapper, "flex gap-4")}>
          <div className="basis-1/2">
            <label htmlFor="input">full name *</label>
            <Input
              placeholder="Enter your full name"
              value={formValues.fullName}
              onChangeFunc={(value: string) => handleSingleFieldChange("fullName", value)}
              onBlur={() => handleFieldBlur("fullName", "", errors.fullName)}
            />
            <p className={cn(styles.error)}>{errors.fullName}</p>
          </div>
          <div className="basis-1/2">
            <label htmlFor="input">phone number *</label>
            <div className="flex gap-[6px]">
              <div>
                <Select
                  isFullWidth={false}
                  value={formValues.countryCode}
                  onChangeFunc={(value: string) => handleSingleFieldChange("countryCode", value)}
                  options={[ "Code" , ...countryCodeOptions]}
				  disabled={disabledButtonPaths.includes(path)}
                />
              </div>
              <div className="w-full">
                <Input
                  placeholder="Enter your no."
                  value={formValues.phoneNumber}
                  onChangeFunc={handlePhoneNumberChange}
                  onBlur={() => handleFieldBlur("phoneNumber", "", errors.phoneNumber)}
                />
              </div>
            </div>
            <p className={cn(styles.error)}>{errors.countryCode || errors.phoneNumber}</p>
          </div>
        </div>

        <div className={cn(styles.commonMargins, styles.emailContainer, "flex gap-4")}>
          <div className="basis-1/2">
            <label htmlFor="input">email id *</label>
            <Input
              placeholder="abc@xyz.com"
              value={formValues.emailId}
              onChangeFunc={(value: string) => handleSingleFieldChange("emailId", value)}
              onBlur={() => handleFieldBlur("emailId", "", errors.emailId)}
            />
            <p className={cn(styles.error)}>{errors.emailId}</p>
          </div>
          <div className="basis-1/2">
            {path === "/" || isNewLeadForm ? (
              showProgramField && (
                <>
                  <label>Degree you are looking for *</label>
                  <Select
                    value={formValues.degree}
                    onChangeFunc={(value: string) => {
                      handleSingleFieldChange("degree", value);
                    }}
                    options={[SELECT_DEGREE, ...degreeOptions]}
                  />
                  <p className={cn(styles.error)}>{errors.degree}</p>
                </>
              )
            ) : (
              <div className="basis-1/2">
                <label>Degree you are looking for *</label>
                <Select
                  value={formValues.degree}
                  onChangeFunc={(value: string) => {
                    handleSingleFieldChange("degree", value);
                  }}
                  options={[SELECT_DEGREE, ...degreeOptions]}
                />
                <p className={cn(styles.error)}>{errors.degree}</p>
              </div>
            )}
          </div>
        </div>
        <div></div>
        <div>
          {path === "/" || isNewLeadForm ? (
            showProgramField && (
              <div className={cn(styles.commonMargins, "w-full")}>
                <label>Programs you are looking for *</label>
                <Select
                  value={formValues.program}
                  onChangeFunc={(value: string) => {
                    handleSingleFieldChange("program", value);
                  }}
                  options={["Select Program", ...programsList]}
                />
                <p className={cn(styles.error)}>{errors.program}</p>
              </div>
            )
          ) : (
            <div className={cn(styles.commonMargins, "w-full")}>
              <label>Programs you are looking for *</label>
              <Select
                value={formValues.program}
                onChangeFunc={(value: string) => {
                  handleSingleFieldChange("program", value);
                }}
                options={["Select Program", ...programsList]}
              />
              <p className={cn(styles.error)}>{errors.program}</p>
            </div>
          )}
        </div>
        <div className={cn(styles.commonMargins, "flex items-start gap-[17px]")}>
          <div className="shrink-0">
            <Checkbox
              name="consent"
              value={formValues.isTermsAccepted}
              onChangeFunc={(value: boolean) => handleSingleFieldChange("isTermsAccepted", value)}
              onBlur={() => handleFieldBlur("isTermsAccepted", "", errors.isTermsAccepted)}
            />
          </div>
          <p className={cn(styles.authorization)}>
            I authorise Amity University Online and its associates to contact me with updates &
            notifications via Email, SMS, WhatsApp, and Voice call as per the Privacy Policy. This
            consent will override any registration for DNC / NDNC.
          </p>
        </div>
        <SubmitButton
          text={isLoading || isOTPLoading ? "Submitting" : "Submit"}
          isDisabled={isLoading || isOTPLoading || !isFormValid}
        />
      </form>
    </>
  );
}
