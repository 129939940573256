import React from "react";
import cn from "clsx";

export default function Checkbox({
  value,
  onChangeFunc,
  name,
  onBlur,
  isDark = false,
}: {
  name?: string;
  value: boolean;
  onChangeFunc: (value: boolean) => void;
  onBlur? : () => void;
  isDark?: boolean;
}) {
  return (
    <input
      type="checkbox"
      checked={value}
      name={name}
      onChange={() => onChangeFunc(!value)}
      onBlur={onBlur}
      className={cn(
        "outline-none rounded-[50%] align-middle border-[1px] border-stone-500 appearance-none cursor-pointer",
        value && !isDark && "checked:bg-primary-blue checked:border-transparent checked:ring-2 checked:ring-primary-blue checked:ring-offset-2 checked:ring-offset-white",
        value && isDark && "checked:bg-white checked:border-transparent checked:ring-2 checked:ring-white checked:ring-offset-2 checked:ring-offset-black",
        isDark && "bg-transparent border-stone-500 text-white"
      )}
    />
  );
}