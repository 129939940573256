"use client";
import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import clsx from "clsx";
import Markdown from "@/components/atoms/Markdown";

import styles from "./WhyAmity.module.scss";

const DiverseLeaningMediums = dynamic(() => import("@/SvgIcons/DiverseLearningMediums"));
const DedicatedAcademicAdvisor = dynamic(() => import("@/SvgIcons/DedicatedAcademicAdvisor"));
const AIProfessional = dynamic(() => import("@/SvgIcons/AIProfessor"));
const DoorStepDeliveryOfBooks = dynamic(() => import("@/SvgIcons/DoorStepDeliveryOfBooks"));
const MetaverseCampus = dynamic(() => import("@/SvgIcons/MetaverseCampus"));
const OfflineExperience = dynamic(() => import("@/SvgIcons/OfflineExperiences"));
const PlacementOpportunities = dynamic(() => import("@/SvgIcons/PlacementOpportunities"));
const CarreerServices = dynamic(() => import("@/SvgIcons/CareerServices"));

const SVG_DATA = [
  {
    heading: "Diverse learning mediums",
    subHeading:
      "E-books, printed & audio books, videos to cater your preferences & unique learning style",
    component: DiverseLeaningMediums,
  },
  {
    heading: "Dedicated Academic Advisor",
    subHeading:
      "Dedicated experts and advisor to guide you at every step of your professional career",
    component: DedicatedAcademicAdvisor,
  },
  {
    heading: "AI-Professor AMI",
    subHeading: "Powered by Chat-GPT 4 and open AI-driven technology for online learning",
    component: AIProfessional,
  },
  {
    heading: "Career Services",
    subHeading: "Be job-ready with resume building workshops, internships & industry mentorship",
    component: CarreerServices,
  },
  {
    heading: "Doorstep delivery of books",
    subHeading:
      "India’s only online university to provide physical books to read at your door step",
    component: DoorStepDeliveryOfBooks,
  },
  {
    heading: "Metaverse Campus",
    subHeading:
      "Discovering endless possibilities of Amity campus through our metaverse tour experience",
    component: MetaverseCampus,
  },
  {
    heading: "Offline Experiences",
    subHeading:
      "Weave a close bond with faculty & peers, via on campus orientation programs and activities",
    component: OfflineExperience,
  },
  {
    heading: "Placement opportunities",
    subHeading:
      "Job interview prep, placement assistance & resume building for students to be job-ready",
    component: PlacementOpportunities,
  },
];

const IntersectionObserverWrapper = ({ children }) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      entry.isIntersecting ? setIntersecting(entry.isIntersecting) : null
    );
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.intersectionDiv} ref={ref}>
      {isIntersecting ? children : null}
    </div>
  );
};

export default function WhyAmityItemClientWrapper() {
  return (
    <>
      {SVG_DATA.map(({ heading, subHeading, component }) => {
        const SVGComp = component;
        return (
          <React.Fragment key={heading}>
            <div className={clsx("font-raleway", styles.item, styles.centerdItem)}>
              <div className={styles.wrapperIntersection}>
                <IntersectionObserverWrapper>
                  <SVGComp />
                </IntersectionObserverWrapper>
              </div>

              <Markdown as="h3" className={styles.title}>
                {heading}
              </Markdown>

              <p className={clsx("text-sec", styles.subtitle)}>{subHeading}</p>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}
