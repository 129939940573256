"use client"
import styles from "./PageCard.module.scss";
import cn from "clsx";
import Markdown from "@/components/atoms/Markdown";

export default function PageCardImmersion({
  BGColor,
  TextColor,
  BorderColor,
  Icon,
  Title,
  Content,
  hasBorder = false,
  SubtitleLabel = "",
  Subtitle,
  imageLoadingStrategy = "eager",
  isOutline,
  fontSizeTitle,
  fontSizeContent,
  fontSizeDescription,
  TitleDescription,
  isLastCard=false,
  Image
}: any) {
  if (!Title?.enabled && !Content?.enabled && !TitleDescription?.enabled) {
    return null;
  }

  return (
    <div className={styles.announcementCard} >
      <div
        className={cn(isLastCard? "" :styles.announcementInner, {
          [styles.border]: hasBorder,
          [styles.bgColor] : BGColor
        })}
      >
        
        {/* Front Side */}
        <div className={styles.announcementItem} style={{borderColor : BGColor || BorderColor, backgroundColor : BGColor}}>
        {isLastCard ? (
            <div className={cn(styles.Attraction__lastCardImageContainer)}>
                <img
                src={Image?.source?.data?.[0]?.attributes?.url}
                alt={Image?.alt || "Last Card Image"}
                width={300} 
                height={200} 
                className={cn(styles.Attraction__lastCardImage)}
                />
          </div>
        ) : (
            <>
                {Title?.enabled && (
                <div className={styles.announcementTitle}>
                    <Markdown
                    className={cn(styles.title, {
                        [styles.titleWithSubtitle]: Icon?.source,
                    })}
                    style={{ fontSize: fontSizeTitle, color : TextColor }}
                    sanitize={false}
                    >
                    {Title?.text}
                    </Markdown>
                    {TitleDescription?.enabled && (
                    <Markdown
                    as="p"
                    className={styles.titleDescription}
                    style={{ fontSize: fontSizeDescription }}
                    sanitize={false}
                    >
                    {TitleDescription?.text}
                    </Markdown>
                    )}
                </div>
                )}

                {SubtitleLabel && Subtitle ? (
                    <div>
                        <div className={cn(styles.Announcement__subtitle)}>
                            <Markdown
                                as="h3"
                                className={cn("font-semibold", styles.Announcement__subtitleLabel)}
                                style={{
                                color: TextColor
                                }}
                                sanitize={false}
                            >
                                {SubtitleLabel.text?.replace(/\n/gi, "  \n")}
                            </Markdown>
                        </div>
                        {Subtitle?.enabled ? (
                        <Markdown as="h3" className="font-bold xl:font-extrabold"
                            style={{
                                color: TextColor
                            }}
                            sanitize={false}>
                            {Subtitle.text}
                        </Markdown>
                        ) : null}
                    </div>
                ) : null }

                {Content?.enabled && (
                <div className={styles.contentParent}>
                    <Markdown
                    className={styles.content}
                    style={{ fontSize: fontSizeContent }}
                    sanitize={false}
                    >
                    {Content?.text}
                    </Markdown>
                </div>

                )}
            </>
        )}
        </div>
      </div>
    </div>
  );
}

