import styles from "./Input/Input.module.scss";
import cn from "clsx";

export default function SubmitButton({
  isDisabled = true,
  text = "Submit",
  bgColor = "#1a325d",
  textColor = "#fff",
}: {
  isDisabled?: boolean;
  text?: string;
  bgColor?: string;
  textColor?: string;
}) {
  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={cn("font-semibold font-raleway uppercase w-full border", styles.btn, {
        ["bg-[#E7E7E7] border-stone-500 text-stone-500"]: isDisabled,
        ["bg-primary-blue border-blue-950 text-white"]: !isDisabled,
      })}
      style={isDisabled ? {} : { background: bgColor, color: textColor, borderColor: textColor }}
    >
      {text}
    </button>
  );
}
