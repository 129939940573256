"use client";

import ClickToScroll from "@/components/sections/NewLists/ClickToScroll";
import styles from "./styles.module.scss";
import DegreeProgramCard from "@molecules/DegreeProgramCard/DegreeProgramCard";
import { useEffect, useMemo, useState } from "react";
import cn from "clsx";
import Button from "@/components/atoms/Button";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";

const NEEDED_DATA_COUNT = 5;

export default function SimilarProgramsSection({
  programs,
  specializations,
  isDesktop,
  isHomepage = false,
  CTA = null,
  title
}) {
  const [data, setData] = useState([]);

  const setProgramsCount = useMemo(() => {
    //? first take all the programs and if still programs are less then 5 then take specializations to complete the list of 5 data
    const programsData = programs?.slice(0, NEEDED_DATA_COUNT);
    const specializationsData = specializations?.slice(
      0,
      NEEDED_DATA_COUNT - programsData?.length
    );
    return [...programsData, ...specializationsData];
  }, [programs, specializations]);

  useEffect(() => {
    setData(setProgramsCount);
  }, [setProgramsCount]);

  const GTEventsHandler = () => {
    pushToDataLayer({
      'event':'view_all_cta_click',
      'cta_text': CTA.text,
      'section_name': title,
      'page_type': getGlobalStateValue('pageType')
    })
  }

  if (isDesktop) {
    return (
      <div
        className={cn({
          [styles.paddingBottom]: isHomepage,
        })}
      >
        <div className={cn(styles.root)} id="programlist">
          <ClickToScroll id="programlist" />
          {data?.map((d, index) => (
            <DegreeProgramCard
              key={index}
              {...d?.attributes?.BasicDetails}
              isSmall
              scroll
              title={title}
            />
          ))}
        </div>
        {CTA?.enabled ? (
          <div className={styles.ctaBtn}>
            <Button
              buttonProps={{
                href: CTA.url,
                target: CTA?.target?.[0] || CTA?.target,
                onClick: GTEventsHandler
              }}
              icon="/_a/icons/left-arrow.svg"
              hoverIcon="/_a/icons/left-arrow-white.svg"
              alt = "Arrow Icon"
              title="Arrow Icon"
              bg="o"
            >
              {CTA.text}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={cn(styles.root, {
        [styles.paddingBottom]: isHomepage,
      })}
    >
      {data?.map((d, index) => (
        <DegreeProgramCard
          key={index}
          {...d?.attributes?.BasicDetails}
          isSmall
          scroll
          title={title}
        />
      ))}
      {CTA?.enabled ? (
        <div className={styles.ctaBtn}>
          <Button
            buttonProps={{
              href: CTA.url,
              target: CTA?.target?.[0] || CTA?.target,
              onClick: GTEventsHandler
            }}
            icon="/_a/icons/left-arrow.svg"
            hoverIcon="/_a/icons/left-arrow-white.svg"
            bg="o"
            alt="Arrow Icon"
          >
            {CTA.text}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
