"use client";

import Modal, { ModalDialog } from "@molecules/Modal";
import MediaFormSection from "@organisms/MediaFormSection/MediaFormSection";
import dynamic from "next/dynamic";
import { OTPContextProvider } from "./context";
import EmiModalContent from "@organisms/FeesSection/EmiModalContent";
import { IModalActions, ModalTypes } from "@/types/modal";
import { Suspense, useContext, useEffect, useRef } from "react";
import { ModalContext } from "@/context/ModalContext"
import { StorageService } from "@/helpers/storage.service";
import { pushToDataLayer } from "@/helpers/gtag";
import ConnectModal from "@organisms/modal/ConnectModal/ConnectModal";
import LandingPageConnectModalTempleteOne from "@organisms/modal/ConnectModalFormLPTempleteOne/LandingPageConnectModalTempleteOne";
import { getGlobalStateValue } from "@/context/utils";
import LoginModal from "@organisms/LoginModal";
import DownloadBrochure from '@organisms/modal/DownloadBrochure';

const ConnectModalLP = dynamic(() => import("@/components/organisms/modal/ConnectModalLP/ConnectModalLP"), {
  ssr: false,
});

const VideoPopup = dynamic(
  () => import("@organisms/VideoPopup/VideoPopup"),
  {
    ssr: false,
  }
);
const CallUsModal = dynamic(
  () => import("@organisms/modal/CallUsModal"),
  {
    ssr: false,
  }
);

const DownloadBrochureLP = dynamic(
  () => import("@organisms/modal/DownloadBrochureLP/DownloadBrochureLP"),
  {
    ssr: false,
  }
);

const PreDegreeModal = dynamic(
  () => import("@organisms/modal/PreDegreeModal/PreDegreeModal"),
  {
    ssr: false,
  }
);

const RegistrationForm = dynamic(
  () =>
    import("@organisms/modal/RegistrationForm/RegistrationForm"),
  {
    ssr: false,
  }
);

const registrationModalData = {
  Heading:
    "To know more about our career services, register now and our team will reach out to you",
  Image: {
    alt: "Registration form",
    enabled: true,
    source: {
      data: {
        attributes: {
          url: "https://amity-strapi-file-uploads.s3.ap-south-1.amazonaws.com/shaking_hand_speaker_f89bb6a967.png",
        },
      },
    },
  },
};

const renderModalContent = ({
  modalType,
  modalData,
  isMobile,
  onClose,
}: {
  modalType: ModalTypes
  modalData: any
  isMobile: boolean
  onClose: any
}) => {
  const {
    url = "",
    programTitle = "",
    associatedPrograms = [],
    isLandingPage = false,
    specializationText,
    Custom10PgType="",
    isNewLeadForm = true,
    popup={}
  } = modalData || {}

  switch (modalType) {
    case ModalTypes.VideoPopup:
      return <VideoPopup url={url} />;
    case ModalTypes.LoginModal:
      return <LoginModal isMobile={isMobile} />;

    case ModalTypes.ConnectModalLP:
      return (
        <ConnectModalLP
          {...modalData}
          isMobile={isMobile}
          associatedPrograms={associatedPrograms}
          isLandingPage={isLandingPage}
          isNewLeadForm={isNewLeadForm}
          popup={popup}
        />
      );

    case ModalTypes.ConnectModal:
      return (
        <Suspense fallback={null}>
          <ConnectModal
            {...modalData}
            isMobile={isMobile}
            associatedPrograms={associatedPrograms}
            isLandingPage={isLandingPage}
            isNewLeadForm={isNewLeadForm}
            popup={popup}
          />
        </Suspense>
      );

    case ModalTypes.LandingPageConnectModalTempleteOne:
      return (
        <Suspense fallback={null}>
          <LandingPageConnectModalTempleteOne
            {...modalData}
            isMobile={isMobile}
            associatedPrograms={associatedPrograms}
            isLandingPage={isLandingPage}
            isNewLeadForm={isNewLeadForm}
            popup={popup}
          />
        </Suspense>
      );

    case ModalTypes.DownloadBrochure:
      return (
        <Suspense fallback={null}>
          <DownloadBrochure
            isMobile={isMobile}
            programTitle={programTitle}
            onClose={onClose}
            specializationText={specializationText}
            Custom10PgType={Custom10PgType}
          />
        </Suspense>
      );
    case ModalTypes.PreDegreeModal:
      return (
        <Suspense fallback={null}>
          <PreDegreeModal {...modalData} isMobile={isMobile} />
        </Suspense>
      );

    case ModalTypes.DownloadBrochureLP:
      return (
        <Suspense fallback={null}>
          <DownloadBrochureLP
            {...modalData}
            isMobile={isMobile}
            programTitle={programTitle}
            onClose={onClose}
            specializationText={specializationText}
            Custom10PgType={Custom10PgType}
          />
        </Suspense>
      );
    case ModalTypes.CallUsModal:
      return <CallUsModal isMobile={isMobile} />;
    case ModalTypes.emiModal:
      return <EmiModalContent Fees={modalData} />;
    case ModalTypes.RegistrationForm:
      return (
        <MediaFormSection {...registrationModalData} isMobile={false}>
          <RegistrationForm />
        </MediaFormSection>
      );
  }
}

const CommonModals = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  const { modalState, modalDispatch } = useContext(ModalContext)
  const { isModalOpen, isModalForceClose, modalType, modalData } = modalState;
  const isSessionModalActive = StorageService.get("timeConnectModal")
  const imageLink = useRef("https://amityonline.com/_s/image_23bbfe1a70.png");

  useEffect(()=>{
    const handleQueryBasedModal = async () => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const modalId = getUrlParams.get('modalId') || "";
      if (ModalTypes[modalId]) {
        modalDispatch({
          type: IModalActions.OpenModal,
          data: { modalType: ModalTypes[modalId], isModalOpen: true }
        });
      }
    };
    handleQueryBasedModal();
  },[]);

  useEffect(()=>{
    const pathName = window.location.pathname;
    const modalElement = document.querySelector('div#_modal')
    if(!isModalOpen && !isModalForceClose && !isSessionModalActive && !pathName.includes("lp-nw")){
      const timer = setTimeout(() => {
        if(!modalElement.childElementCount){
          modalDispatch({ 
            type:  IModalActions.UpdateModal,
            data: { modalType: ModalTypes.CallUsModal, isModalOpen : true }
          });
          StorageService.set("timeConnectModal", true)
          clearTimeout(timer);
        }
      }, 30000);
      return () => clearTimeout(timer);
    }
  },[isModalForceClose]);

  const onCloseHandler = ()=>{
    if(getGlobalStateValue('pageType') === "Program Page" && modalType===ModalTypes.DownloadBrochure){
      pushToDataLayer({
        'event':  'thank_you_card_cta_interaction',
        'cta_text':'close',
        'section_name': getGlobalStateValue('parentSection') || "NA",
        'page_type': getGlobalStateValue('pageType')
      })
    }
    modalDispatch({ type: IModalActions.CloseModal })
  }


  useEffect(()=>{
    fetchExitIntentUp();
  },[])
  
  const fetchExitIntentUp = async () => {
    const fetchData = await fetch("/api/exit-intent-popup");
    const { res : { data }} = await fetchData.json();;
    imageLink.current = data?.uploadFiles?.data[0]?.attributes?.url || "https://amityonline.com/_s/image_23bbfe1a70.png";
  }

  useEffect(() => {
    const handleEventListener = (event : any)=> {
      const documentTarget = event.relatedTarget;
      if (!documentTarget && event.clientY < 0) {
        StorageService.set("preDegreeModal", true);
        document.removeEventListener("mouseout", handleEventListener);
        modalDispatch({
          type: IModalActions.OpenModal,
          data: { modalType: ModalTypes.PreDegreeModal, isModalOpen: true, modalData: {
            url: imageLink.current
          } },
        });
      }
    }

    if(!isMobile && !StorageService.get("preDegreeModal") && !window.location.pathname.includes('/lp-nw')){
      document.addEventListener("mouseout", handleEventListener);
    }
  
    return () => {
      document.removeEventListener("mouseout", handleEventListener);
    }
  }, []);

  return isModalOpen ? (
    <OTPContextProvider>
      <Modal modalId={modalType}>
        <ModalDialog
          onClose={onCloseHandler}
          modalType={modalType}
          size={modalType === ModalTypes.emiModal && "xl"}
          isMobile={isMobile}
        >
          {renderModalContent({
            modalType,
            modalData,
            isMobile,
            onClose: () => modalDispatch({ type: IModalActions.CloseModal }),
          })}
        </ModalDialog>
      </Modal>
    </OTPContextProvider>
  ) : null
}

export default CommonModals
