"use client";

import { useRouter, usePathname, useSearchParams } from "next/navigation"
import { captureLead } from "@/api/leadSquare";
import React, { useEffect, useState, useCallback, useContext, useRef } from "react"
import style from "./LandingPageConnectModalTempleteOne.module.scss"
import cn from "clsx"
import CommonButton from "@/components/common/Analytics/CustomButton";
import { GAEvent, GA_EVENTS_PAYLOAD } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";
import { usePlatformAndOS } from "@/helpers/usePlatformAndOS";
import { useDeviceInfo } from "@/helpers/userDeviceType";
import { getGAPayload } from "@/helpers/getGAPayload";
import { ModalContext } from "@/context/ModalContext";
import { IModalActions } from "@/types/modal";
import ApplicationFormRedirectLoader from "@/components/molecules/ApplicationFormRedirectLoader/ApplicationFormRedirectLoader";
import {
	OTPContext,
	OTPContextProvider,
	OTPContextWrapper,
  } from "@/components/sections/CommonModals/context";

import { updateStateSession } from "@/context/utils";
import LandingPageConnectModalFormTempleteOne from "../../../molecules/Forms/LandingPageConnectModalTempleteOne/LandingPageConnectModalFormTempleteOne";

export default function LandingPageConnectModalTempleteOne({
	isMobile,
	associatedPrograms,
	isLandingPage,
	isNewLeadForm,
  popup,
  isLambdaAPI = false
}: {
	isMobile: boolean,
	associatedPrograms: Array<ProgramDetails>,
	isLandingPage: boolean,
	isNewLeadForm: boolean
  popup:Popup
  isLambdaAPI: boolean
}) {
  const path = usePathname()
  const searchParams = useSearchParams();
  const router = useRouter()
  const closeModal = () => {
    router.replace(path, {
      scroll: false,
    })
  }
  const isLandingPageUrl = path.includes('/lp-nw');
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(null);
  const [modalData, setModalData] = useState<Popup | null>(null);
  const [formResponse, setFormResponse] = useState(null);
  const [formValuesData, setFormValuesData] = useState(null);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageReferrer, setPageReferrer] = useState<string>('');
  const [isOtpValidated,setIsOtpValidated]=useState(false)
  const { platform = "", version = "" } = usePlatformAndOS();
  const deviceType = useDeviceInfo();
  const { HERO_CARD_LEAD_VERIFIED, HERO_CARD_CANCEL, HERO_CARD_PROCEED } = GAEvent;
  const [redirectTime, setRedirectTime] = useState(3);
  const { modalDispatch } = useContext(ModalContext);
  const [ leadFailed, setLeadFailed ] = useState(false);
  const emailIdRef = useRef(null);

  const gaOtherPayloadInfo = {
	platform,
	deviceType,
	version,
	pageTitle,
	pageReferrer,
	pageUrl : typeof window !== 'undefined' ? window.location.href : '',
  }
  const gaEnhancedConverstionData = {
	enhanced_conversion : {
		emailId : formValuesData?.emailId || "",
		phoneNumber : formValuesData?.phoneNumber || "",
	},
	enhanced_conversion_data : {
		emailId : formValuesData?.emailId || "",
	}
  };

  const isLeadFailed = (status: boolean) => {
    setLeadFailed(status)
  }
  
  useEffect(()=>{
 
    updateStateSession({
      formSectionName : "Connect with our counsellor"
    });

  },[]);
  
  useEffect(() => {
    const fetchModalData = async () => {
      try {
        const res = await fetch('/api/connect-modal');
        const { data = {} } = await res.json();
        setModalData({ ...data });
      } catch (error) {
        console.error(error);
      }
    };

    if (Object.keys(popup).length === 0) {
      fetchModalData();
    } else {
      setModalData({ ...popup });
    }
    setPageReferrer(document.referrer);
    setPageTitle(document.title);
    const handleTitleChange = () => {
      setPageTitle(document.title);
    };
    document.addEventListener('titlechange', handleTitleChange);
    return () => {
      document.removeEventListener('titlechange', handleTitleChange);
    };
  }, []);


  useEffect(() => {
    if (
      formResponse !== null &&
      formResponse.link !== "" &&
      formResponse.link
    ) {
      const gaData = {
		...GA_EVENTS_PAYLOAD.get(HERO_CARD_LEAD_VERIFIED),
		...gaEnhancedConverstionData
	  };
	  const gaPayload = getGAPayload(gaData,searchParams,gaOtherPayloadInfo);
      pushToDataLayer(gaPayload);
    }
  }, [formResponse]);

	const handleFormSubmit = async (formValues: any) => {
		const res: any = await captureLead(formValues)

		if (!res?.error) {
			setIsFormSubmitted(
				`Your request for counselling has been sent ${
					res?.isDuplicate ? "already" : ""
				}, and our counsellor will reach out to you in the next few hours.`
			)

			setTimeout(() => {
				closeModal()
				setTimeout(() => {
					setIsFormSubmitted(null)
				}, 3000)
			}, 3000)
		} else {
			console.error("error", res?.error)
		}
	}

	const handleOtpValidation = useCallback((status, emailId) => {
		setIsOtpValidated(status);
    emailIdRef.current = emailId;
	}, []);


	useEffect(() => {
        if (isFormSubmitted && isOtpValidated) {
			
        const timer = setInterval(() => {
            setRedirectTime((prevTime) => {
                if (prevTime > 1) {
                    return prevTime - 1;
                } else {
                    clearInterval(timer);
                  
                    sessionStorage.setItem('applicationURL', formResponse.link)
                    sessionStorage.setItem('emailEvent', emailIdRef.current);
            
                    window.location.href = "/thankyou";
    
                    setTimeout(()=> modalDispatch({ type: IModalActions.CloseModal }) , 1500);
					 
                    return prevTime;
                }
            });
        }, 1000);
        }
     }, [isFormSubmitted, isOtpValidated, router]);

	return (
    <>
      <div className={cn(style.root, "relative flex w-full outline-none focus:outline-none ")}>
        {/* left side */}
        <div className="basis-2/5 shrink-0">
          {modalData?.Picture?.enabled && !isMobile &&  !leadFailed &&(
            <img
              src={modalData?.Picture?.source?.data?.[0]?.attributes?.url}
              alt={modalData?.Picture?.alt}
              className={style.img}
            />
          )}
			{modalData?.Picture?.enabled && !isMobile && leadFailed && <img
				src={"/_a/strapi/man-wearing-striped-shirt-uses-laptop-work 1.png"}
				alt={modalData?.Picture?.alt}
				className={style.img}
			/>}
        </div>
        {/* right side */}
        {isFormSubmitted && isOtpValidated ? (
          <ApplicationFormRedirectLoader redirectTime={redirectTime} text={isLandingPageUrl ? "" : undefined} />
        ) : (
         <div className={style.formSection}>    
             <div className="mx-auto xl:pl-0 w-full">
            {
              <OTPContextProvider isLambdaAPI={isLambdaAPI} >
                <OTPContext.Consumer>
                  {({ isHeaderHidden }) => (
                    <>
                          {!leadFailed && (<>
                            <h2
                              className={cn({
                                [style.Modal__RightContainerTitle]: true,
                                [style.thankYou]: isFormSubmitted && isOtpValidated,
                              })}
                            >
                              {isFormSubmitted && isOtpValidated && "Thank you!"}
                            </h2>
                            <h2
                              className={cn({
                                [style.Modal__RightContainerTitle]: true,
                              })}
                            >
                              {!isHeaderHidden && "Connect with our counsellor"}
                            </h2>
                          </>)
                          }
                          {!isOtpValidated && !isHeaderHidden && !leadFailed && (
                            <p className={` ${style.Modal__RightContainerDescription} w-full`}>
                              "Please provide the information below, and our counsellor will get in touch
                              with you."
                            </p>
                          )}
                      <OTPContextWrapper>
                        <>
                          {formResponse !== null && isOtpValidated && (
                            <div className="flex gap-x-4">
                              <CommonButton
                                GAData={{
                                  ...GA_EVENTS_PAYLOAD.get(HERO_CARD_CANCEL),
                                  ...gaEnhancedConverstionData,
                                }}
                                pageType="origin"
                                href={path}
                                type={"button"}
                                size="l"
                                bg="o"
                                className="flex justify-center items-center text-xs"
                                closeModal={true}
                              >
                                CANCEL
                              </CommonButton>
                              <CommonButton
                                GAData={{
                                  ...GA_EVENTS_PAYLOAD.get(HERO_CARD_PROCEED),
                                  ...gaEnhancedConverstionData,
                                }}
                                pageType="origin"
                                href={`${formResponse.link}${formResponse.link ? "&e=1" : ""}`}
                                size="l"
                                color="b"
                                icon="/_a/icons/left-arrow-white.svg"
                                className="flex justify-center items-center text-xs"
                              >
                                PROCEED
                              </CommonButton>
                            </div>
                          )}
                          {isFormSubmitted && isOtpValidated ? null : (
                        
                            <LandingPageConnectModalFormTempleteOne
                              setMessage={setIsFormSubmitted}
                              setFormResponse={setFormResponse}
                              isOtpValidated={handleOtpValidation}
                              gaOtherPayloadInfo={gaOtherPayloadInfo}
                              setFormValuesData={setFormValuesData}
                              associatedPrograms={associatedPrograms}
                              isLandingPage={isLandingPage}
                              isNewLeadForm={isNewLeadForm}
							                setLeadFailed={isLeadFailed}
                              isLambdaAPI={isLambdaAPI}
                            />
                
                          )}
                        </>
                      </OTPContextWrapper>
                    </>
                  )}
                </OTPContext.Consumer>
              </OTPContextProvider>
            }
          </div>

         </div> 
        )}
      </div>
    </>
  );
}
