import styles from "./header.module.scss";
import cn from "clsx";
import DownLtLogo from "~/icons/down-lt-white.svg";
import Image from "next/image";
import CommonButton from "@/components/common/Analytics/CustomButton";
import dynamic from "next/dynamic";
import { GAEvent, GA_EVENTS, GA_EVENTS_PAYLOAD } from "@/helpers/gaEvents";
import { Suspense, useContext } from "react";
import { IModalActions, ModalTypes } from "@/types/modal";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";
import { ModalContext } from "@/context/ModalContext";
import VideoCall from "./VideoCall/VideoCall";
import RightArrow from "@/SvgIcons/RightArrow";

const ProgramMenu = dynamic(() => import("@molecules/ProgramMenu/ProgramsMenu"));

const { NAVBAR_ENROLL_NOW, CALL_US_NAVBAR, NAVIGATION_CLICK, CALL_CHAT_INTERACTION, LEAD_INITIATE } = GAEvent
 export default function DesktopMenu({
	menuOpen, 
	headerMenu, 
	programMenuOpen,
	toggleScroll,
	calculateTopForMenu,
	setProgramMenuOpen,
	programMenuRef,
	programMenuList,
	programDomains,
	toggleSearch,
	showSearchBox,
	SearchBox,
  associatedPrograms,
  slug
	}){

    const { modalDispatch } = useContext(ModalContext);

    const onLoginHandler = () =>{
      modalDispatch({
        type: IModalActions.OpenModal,
        data: {
          modalType: ModalTypes.LoginModal,
          modalData: { associatedPrograms, isLandingPage : true, },
        },
      });
    }



    const handleGaClick = (payload={}) => {
      if (typeof window !== 'undefined') {
        const gaData = GA_EVENTS.get(NAVIGATION_CLICK);
        pushToDataLayer({
          ...gaData,
          ...payload, 
          page_type: getGlobalStateValue('pageType')  || 'Home Page'
        });
      }
    }

    return (
      <div className={cn(styles.desktopMenu)}>
        <div
          className={cn(
            `list-none text-base font-raleway tracking-widest flex items-center ${
              menuOpen ? "flex-col xl:flex-row" : ""
            }`,
            styles.menuContainer
          )}
        >
          {headerMenu?.MenuItem?.filter((x) => x.enabled)?.map(
            ({ Item, SubItem }, index) => (
              <div
                className={cn("flex items-center group w-max", styles.menuItem)}
                key={index}
              >
                {index !== 0 ? (
                  <a
                    href={Item.url || ""}
                    target={Item.target || "_self"}
                    key={Item.id}
                    className="flex justify-center items-center"
                    onClick={()=>handleGaClick(
                      {
                        cta_text: Item?.text || "", 
                        category : Item?.title || "",
                        sub_category : "NA",
                        section_name : "header",
                        redirection_url : Item?.url || "",
                        header_title : "NA"
                      }
                    )}
                  >
                    <span
                      className={cn(
                        "mr-1 uppercase laptop:font-medium",
                        styles.menuText,
                        {
                          ["flex gap-1 items-center"]: index === 0,
                          [styles.openMenu]: index === 0 && programMenuOpen,
                        }
                      )}
                    >
                      {Item.text}
                      {index == 0 && (
                        <Image src={DownLtLogo} alt="more items" />
                      )}
                    </span>
                  </a>
                ) : (
                  // program menu dropdown
                  <div
                    key={Item.id}
                    className="flex justify-center items-center cursor-pointer"
                    onClick={(e) => {
                      calculateTopForMenu(e)
                      if (programMenuOpen) toggleScroll(false)
                      setProgramMenuOpen(!programMenuOpen)
                    }}
                  >
                    <span
                      className={cn(
                        `mr-1 uppercase laptop:font-medium ${index == 0 ? 'headFootSprite serviceMenu pr-5' : ''}`,
                        styles.menuText,
                        {
                          ["flex gap-1 items-center"]: index === 0,
                          [styles.openMenu]: index === 0 && programMenuOpen,
                        }
                      )}
                    >
                      {Item.text}
                    </span>
                  </div>
                )}

                {index == 0 && (
                  <div
                    ref={programMenuRef}
                    className={cn(
                      "container w-full fixed",
                      programMenuOpen ? styles.programMenu : '',
                    )}
                  >
                    <Suspense fallback={null}>
                      <ProgramMenu
                        programMenu={programMenuList}
                        programDomains={programDomains}
                        closeMenu={() => {
                          setProgramMenuOpen(false)
                          toggleScroll(false)
                        }}
                        programMenuOpen={programMenuOpen}
                      />
                    </Suspense>
                  </div>
                )}
              </div>
            )
          )}
          
          <div 
           id = "searchContainer"
          className={cn("cursor-pointer relative", styles.searchBox)}>
            <div
              onClick={() => toggleSearch((v) => !v)}
              className={cn(styles.searchIcon, styles.searchIconNew, {
                ["absolute top-1/2 -translate-y-1/2"]: showSearchBox,
              })}
            />
            {showSearchBox && SearchBox} 
          </div>
          {slug !== "/predegree" && <CommonButton
              className={cn(styles.callUsBtn, "flex mr-5 font-semibold !py-5 !px-4")}
              GAData={GA_EVENTS.get(CALL_US_NAVBAR)}
              GADataNew={{
                ...GA_EVENTS.get(CALL_CHAT_INTERACTION),
                cta_text: "call_us",
                section_name: "header",
                page_type: getGlobalStateValue('pageType') 
              }}
              modalType={ModalTypes.CallUsModal}
              type={"button"}
              isEventOnly={true}
            >
              <Image
                src="/_a/icons/outgoing_call.svg"
                alt="Call"
                width={14}
                height={14}
                className={"mr-2"}
              />
              <span className="font-bold mr-0">Call Us</span>
            </CommonButton>}
            <VideoCall isDesktop />
            {slug !== "/predegree" && headerMenu?.LoginCTA?.enabled && (
              <button className={styles.login} onClick={onLoginHandler}>
              {headerMenu.LoginCTA.text} <RightArrow className={styles.svgHover}/>
              </button>
          )
          }
          {slug !== "/predegree" && headerMenu?.CTA?.enabled && (
              <CommonButton
                GAData={GA_EVENTS_PAYLOAD.get(NAVBAR_ENROLL_NOW)}
                GADataNew={{
                  ...GA_EVENTS.get(LEAD_INITIATE),
                  cta_text: headerMenu.CTA.text,
                  section_name: "header",
                  header_title: "NA",
                  page_type: getGlobalStateValue('pageType'),
                  page_url : true
                }}
                onClickStorePayload={
                  {
                    parentSection : "header"
                  }
                }
                type="button"
                modalData={{ associatedPrograms, isLandingPage: true }}
                modalType={ModalTypes.ConnectModal}
                bg="s"
                color="y"
                icon="/_a/icons/left-arrow.svg"
                className={styles.mr}
                isEventOnly={true}
              >
                {headerMenu.CTA.text} 
              </CommonButton>
          )}
          {
          slug === "/predegree" && <a
            href="https://predegrees.amityonline.com/login/index.php"
            className={
              "flex items-center border-[1.5px] border-[#1a325d] mr-4 px-3 py-1 text-[#1a325d] bg-[#ffc907] rounded-[50px]"
            }
          >
            <span className="flex items-center">
              <svg style={{ width: '27px', height: '27px;', fill: "#1b325d" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101">
                <path d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"></path>
              </svg>
              <span className="text-[0.8rem] uppercase font-bold">Login</span>
            </span>
          </a>
        }
        </div>
      </div>
    )
}
